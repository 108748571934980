import React from 'react'
import { OrderHeader } from '../../orderHeader'
import { OrderFooter } from '../../orderFooter'
import { PaymentSection } from '../../../component/order'

export const Payment = () => {
  return (
    <div>
        <OrderHeader/>
        <PaymentSection/>
        <OrderFooter/>
    </div>
  )
}
