import React from 'react';
import seo_banner from "../../../assest/images/seo/seo-banner-img.png"
export const IntroSectionSEO = () => {
  return (
    <div>
           <section class="inner-banner seo-banner">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <h6>SEARCH ENGINE OPTIMIZATION TO</h6>
                  <h1>Revitalize Your Brand’s Image</h1>
                  <p>Go To Logo Experts revolutionizes brand identities and creates successful strategies so your brand can win over audiences in the digital market</p>
               </div>
               <div class="col-md-7">
                  <img src={seo_banner} alt="seo_banner"/>
               </div>
            </div>
            <h3>creative</h3>
         </div>
      </section>
    </div>
  )
}
