import React, { useState, useRef, useEffect } from 'react'
import Slider from "react-slick";
import {
  logoPackages,
  websitePackages,
  videoPackages,
  brandingPackages,
  seoPackages,
  socialPackages,
} from "../../../data/portfolio";
import Shuffle from 'shufflejs';

export const AboutPacakges = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
      };
      const [filterKey, setFilterKey] = useState(".logo");
      const [displayImagesSection, setDisplayImagesSection] = useState(
        logoPackages.slice(0, 6)
      );
      let [getFilter, setGetFilter] = useState("logo");


      useEffect(() => {
        const shuffle = new Shuffle(document.querySelector('.filter-container'), {
          itemSelector: '.filter-item', // Your item selector
          sizer: 'fitRows', // Optional, for specifying the size of items
          // Add more options as needed
        });
      }, []);

      const handleFilterKeyChange = (filter) => {
        const str = filterKey;
        const newStr = str.slice(1);
        setGetFilter(newStr);
    
        console.log(filterKey, filter);
        setFilterKey(filter);
        // Depending on the active button, set the appropriate data array.
        switch (filter) {
          case "website":
            setDisplayImagesSection(websitePackages.slice(0, 6));
            break;
          case "logo":
            setDisplayImagesSection(logoPackages.slice(0, 6));
            break;
          case "video":
            setDisplayImagesSection(videoPackages.slice(0, 6));
            break;
          case "branding":
            setDisplayImagesSection(brandingPackages.slice(0, 6));
            break;
          case "seo":
            setDisplayImagesSection(seoPackages.slice(0, 6));
            break;
          case "social":
            setDisplayImagesSection(socialPackages.slice(0, 6));
            break;
          default:
            // Default to 'logo'
            setDisplayImagesSection(logoPackages.slice(0, 6));
        }
      };
  return (
    <div>
  <section className="our-pricing multiple_pricing combo-pricing">
        <div className="container">
          <div className="text-center">
            <h2>Professional design packages for any budget</h2>
            <p>
              Ready to get started? Solutions devised to best suit your needs.
              We promise you the best logo design service at highly competitive
              prices. Experience the industry’s best design deals structured
              according to your business needs.
            </p>
          </div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <button
                className={`button ${
                  filterKey === "logo" ? "active nav-link" : "nav-link"
                }`}
                onClick={() => handleFilterKeyChange("logo")}
                id="logo-tab"
                data-toggle="tab"
                href="#logo"
                role="tab"
                aria-controls="logo"
                aria-selected="true"
              >
                Logo Design
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`button ${
                  filterKey === ".website" ? "active nav-link" : "nav-link"
                }`}
                onClick={() => handleFilterKeyChange("website")}
                id="website-tab"
                data-toggle="tab"
                href="#website"
                role="tab"
                aria-controls="website"
                aria-selected="false"
              >
                Website Design
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`button ${
                  filterKey === ".video" ? "active nav-link" : "nav-link"
                }`}
                onClick={() => handleFilterKeyChange("video")}
                id="video-tab"
                data-toggle="tab"
                href="#video"
                role="tab"
                aria-controls="video"
                aria-selected="true"
              >
                Video Animation
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`button ${
                  filterKey === ".branding" ? "active nav-link" : "nav-link"
                }`}
                onClick={() => handleFilterKeyChange("branding")}
                id="branding-tab"
                data-toggle="tab"
                href="#branding"
                role="tab"
                aria-controls="branding"
                aria-selected="false"
              >
                Branding
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`button ${
                  filterKey === ".seo" ? "active nav-link" : "nav-link"
                }`}
                onClick={() => handleFilterKeyChange("seo")}
                id="seo-tab"
                data-toggle="tab"
                href="#seo"
                role="tab"
                aria-controls="seo"
                aria-selected="false"
              >
                SEO
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`button ${
                  filterKey === ".social" ? "active nav-link" : "nav-link"
                }`}
                onClick={() => handleFilterKeyChange("social")}
                id="social-tab"
                data-toggle="tab"
                href="#social"
                role="tab"
                aria-controls="social"
                aria-selected="false"
              >
                SMM
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="logo"
              role="tabpanel"
              aria-labelledby="logo-tab"
            >
              <div className="row packages-slider">
                {filterKey == ".logo" ? (
                  <div className="filter-container">
                    <div className={`filter-item logo`}>
                      <Slider {...settings}>
                        {displayImagesSection.map((value) => {
                          return (
                            <div className="item" key={value.id}>
                              <div className="pack">
                                <div className="head">
                                  <h5 className="pckg-title">
                                    {value.heading}
                                  </h5>
                                  <p>{value.desc}</p>
                                  <div className="pckg-hd-title-box">
                                    <h5 className="title">{value.price}</h5>
                                  </div>
                                </div>
                                <div className="mid-body">
                                  <div className="content mCustomScrollbar">
                                    <ul>
                                      {value.list.map((list) => {
                                        return <li>{list}</li>;
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <a
                                  href="javascript:;"
                                  data-fancybox
                                  data-src="#popupform"
                                  className="openpopup various btn btn-blue"
                                >
                                  Start Project
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={`filter-item ${getFilter} `}>
                      <Slider {...settings}>
                        {displayImagesSection.map((value) => {
                          return (
                            <div className="item" key={value.id}>
                              <div className="pack">
                                <div className="head">
                                  <h5 className="pckg-title">
                                    {value.heading}
                                  </h5>
                                  <p>{value.desc}</p>
                                  <div className="pckg-hd-title-box">
                                    <h5 className="title">{value.price}</h5>
                                  </div>
                                </div>
                                <div className="mid-body">
                                  <div className="content mCustomScrollbar">
                                    <ul>
                                      {value.list.map((list) => {
                                        return <li>{list}</li>;
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <a
                                  href="javascript:;"
                                  data-fancybox
                                  data-src="#popupform"
                                  className="openpopup various btn btn-blue"
                                >
                                  Start Project
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
