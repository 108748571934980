import React from 'react';
import homeslideone from "../../../assest/images/home-slide-1.png"

export const IntroSection = () => {
  return (
    <div>
  <section class="inner-banner logo-banner">
         <div class="container">
            <div class="row">
               <div class="col-md-6">
                  <h6>CUSTOM LOGO DESIGN</h6>
                  <h1>Innovative Logo Design &amp; Branding Solutions</h1>
                  <p>We are committed towards providing professional logo designs tailored to your brand’s culture, core values & distinction.</p>
               </div>
               <div class="col-md-6">
                  <img src={homeslideone} alt="homeslideone" class="img1"/>
               </div>
            </div>
            <h3>logo design</h3>
         </div>
      </section>
    </div>
  )
}
