import React from 'react';
import Slider from "react-slick";



var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // infinite: false,
    adaptiveHeight: true
    
};

export const Pricing = () => {
  return (
    <div>
        <section className="our-pricing" data-aos="fade-down" data-aos-duration="1500">
        <div className="container">
            <div className="section-heading main-hdg">
                <h3>Affordable & Flexible Premium Logo Design Packages
                </h3>
            </div>
            <div className="row mt-5">
                <Slider {...settings}>
                <div className="col-md-4">
                    <div className="pec-box pec-before packagebox">

                        <div className="pec-name">
                            <h1>Logo Basic</h1>
                            <div className="price">
                                <h3>$35.00</h3>
                                <h2>$117.00</h2>
                            </div>
                        </div>
                        <div className="pec-list pkg_list">
                            <ul>
                                <li>4 Original Logo Concepts</li>
                                <li> 2 Dedicated Logo Designer</li>
                                <li> 4 Revisions</li>
                                <li> With Grey Scale Format</li>
                                <li> Free Icon Design</li>
                                <li> Formats: JPEG Only</li>
                                <li> 24 - 48 Hours Turn Around Time</li>
                                <li className="features">MORE FEATURES</li>
                                <li> 100% Satisfaction</li>
                                <li> 100% Ownership Rights</li>
                                <li> Money Back Guarantee</li>
                                <li> Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div className="pacck-btns">
                            <a href="javascript:void(0);" className="bnr-btn hvr-buzz-out openpopup">Order Now</a>
                            <a href="te:1234567897" className="pack-call"><span> Call Us: </span> <br/>
                                1234567897</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="pec-box pec-before packagebox">
                        <div className="pec-name">
                            <h1>Logo Professional</h1>
                            <div className="price">
                                <h3>$119.00</h3>
                                <h2>$397.00</h2>
                            </div>
                        </div>
                        <div className="pec-list pkg_list">
                            <ul>
                                <li>12 Original Logo Concepts</li>
                                <li>4 Dedicated Logo Designer (Industry Specific)</li>
                                <li>Unlimited Revisions</li>
                                <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                <li>Email Signature Design</li>
                                <li>With Grey Scale Format</li>
                                <li>Free Icon Design</li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li className="features">MORE FEATURES</li>
                                <li>100% Satisfaction</li>
                                <li>100% Ownership Rights</li>
                                <li>Money Back Guarantee</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div className="pacck-btns">
                            <a href="javascript:void(0);" className="bnr-btn hvr-buzz-out openpopup">Order Now</a>
                            <a href="te:1234567897" className="pack-call"><span> Call Us: </span> <br/>
                                1234567897</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="pec-box pec-before packagebox">
                        <div className="pec-name">
                            <h1>Logo Elite</h1>
                            <div className="price">
                                <h3>$299.00</h3>
                                <h2>$849.00</h2>
                            </div>
                        </div>
                        <div className="pec-list pkg_list">
                            <ul>
                                <li>Unlimited Original Logo Concepts</li>
                                <li>8 Dedicated Logo Designer (Industry Specific)</li>
                                <li>Unlimited Revisions</li>
                                <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                <li>500 Business Cards </li>
                                <li>Email Signature Design</li>
                                <li>With Grey Scale Format</li>
                                <li>Free Icon Design</li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li className="features">MORE FEATURES</li>
                                <li>100% Satisfaction</li>
                                <li>Money Back Guarantee</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div className="pacck-btns">
                            <a href="javascript:void(0);" className="bnr-btn hvr-buzz-out openpopup">Order Now</a>
                            <a href="te:1234567897" className="pack-call"><span> Call Us: </span> <br/>
                                1234567897</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="pec-box pec-before packagebox">
                        <div className="pec-name">
                            <h1>The Boss</h1>
                            <div className="price">
                                <h3>$599.00</h3>
                                <h2>$1996.00</h2>
                            </div>
                        </div>
                        <div className="pec-list pkg_list">
                            <ul>
                                <li>3D/Mascot/Animated Logo</li>
                                <li>Unlimited Original Logo Concepts</li>
                                <li>8 Dedicated Logo Designer (Industry Specific)</li>
                                <li> Unlimited Revisions</li>
                                <li>Stationery Design (Business Card, Letterhead, Envelope)</li>
                                <li>500 Business Cards</li>
                                <li>Email Signature Design</li>
                                <li>With Grey Scale Format</li>
                                <li>Free Icon Design</li>
                                <li>Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                <li>24 - 48 Hours Turn Around Time</li>
                                <li className="features">MORE FEATURES</li>
                                <li>100% Satisfaction</li>
                                <li>Money Back Guarantee</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div className="pacck-btns">
                            <a href="javascript:void(0);" className="bnr-btn hvr-buzz-out openpopup">Order Now</a>
                            <a href="te:1234567897" className="pack-call"><span> Call Us: </span> <br/>
                                1234567897</a>
                        </div>
                    </div>
                </div>
                </Slider>
            </div>
        </div>
    </section>
    </div>
  )
}
