import React from 'react';
import ourCatgoryImg from "../../../assest/images/our-category-img.jpg"

export const LogoInfo = () => {
  return (
    <div>
           <section class="our-category logo-cate">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-8">
                  <img src={ourCatgoryImg} alt="ourCatgoryImg"/>
               </div>
               <div class="col-md-4">
                  <div class="txt">
                     <h6>Professional</h6>
                     <h2>Custom Logo Design Company</h2>
                     <p>Go To Logo Experts is a premier custom logo design company established with the goal to bring brands at the top charts by creating awe-inspiring personalities. With our massive logo design online services, we shape the brand identities by incorporating unique aspects and core ideology. We opt for incredible ideas and patterns that can help us reshape the brand identity and improve its clientele. We know the tactics to build a prosperous and prospective lead generation funnel; therefore, we promise cheap and affordable logo creation services. </p>
                     <p>In the realm of visual representation, where brands aspire to stand distinct and memorable, our expertise lies in the art of crafting logo design identities. With meticulous attention to detail, innovative thinking, and a profound understanding of your brand's essence, we embark on a creative journey to bring your logo design to life. </p>
                  </div>
               </div>
            </div>
         </div>     
      </section>
    </div>
  )
}
