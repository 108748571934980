import React, {useState} from 'react'
import { videos } from "../../../data";

export const VideoPortfolio = () => {
    const [displayedImages, setDisplayedImages] = useState(videos.slice(0, 6)); // Initially, show the first 6 images
    const [startIndex, setStartIndex] = useState(6);

    const getMoreImages = () => {
        const endIndex = startIndex + 6;
        setDisplayedImages([
          ...displayedImages,
          ...videos.slice(startIndex, endIndex),
        ]);
    }
  return (
    <div>
 <div className="grid portfolio_section">
        
              {displayedImages.map((video) => (
                <div
                  className={`element-item col-md-4 video `}
                  key={video.id}
                  data-category="video"
                >
                  <a href={video.videoLink} data-fancybox="video">
                    <img src={video.videoImg} className="lazy" />
                  </a>
                </div>
              ))}
          {videos.length && (
            <button onClick={getMoreImages} id="showMore"></button>
          )}
          </div> 
    </div>
  )
}
