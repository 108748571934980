import React, { useState } from "react";
import img1 from "../../../assest/images/contact-icon1.png";
import img2 from "../../../assest/images/contact-icon2.png";
import img3 from "../../../assest/images/contact-icon3.png";
import axios from "axios";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  // http://development.tgcrm.net/api/form_submission?brand_key=135688
  const [response, setResponse] = useState(null);

  const onSubmitFunc = async (e) => {
    e.preventDefault();
    try {
      const apiUrl =
        "http://development.tgcrm.net/api/form_submission?brand_key=135688";
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const formDataString = new URLSearchParams(formData).toString();

      const response = await axios.post(apiUrl, formDataString, config);
      console.log("responseresponseresponse", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <section class="contact-us">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <ul>
                <li>
                  <h2>
                    <img src={img1} alt="img1" />
                    Phone
                  </h2>
                  <p>
                    Have a question? We are available 24/7 to answer your
                    queries.
                  </p>
                </li>
                <li>
                  <h2>
                    <img src={img2} alt="img2" />
                    Email
                  </h2>
                  <p>
                    Our support team will get back to within 48 hours during
                    standard business hours.
                  </p>
                </li>
                <li>
                  <h2>
                    <img src={img3} alt="img3" />
                    Address
                  </h2>
                  <p>We are located at the following address:</p>
                </li>
              </ul>
            </div>
            <div class="col-md-7 offset-1">
              <div class="contact-form">
                <h2>
                  Contact <strong>Us</strong>
                </h2>
                <p>
                  Please feel free to contact us by filling in the form below
                  and our support team will handle the rest.
                </p>
                <form class="contact_form" onSubmit={onSubmitFunc}>
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        name="name"
                        id="cn"
                        placeholder="Name *"
                        class="form-control"
                        required
                        value={formData.name}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                      />
                      <input
                        type="email"
                        name="email"
                        id="em"
                        placeholder="Email Address *"
                        class="form-control email"
                        required
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                      <input
                        type="number"
                        name="phone"
                        id="pn"
                        placeholder="Phone Number *"
                        class="form-control number"
                        required
                        value={formData.phone}
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                      />
                    </div>
                    <div class="col-md-6">
                      <textarea
                        name="message"
                        id="msg"
                        class="form-control"
                        placeholder="Your Message Goes Here"
                        value={formData.message}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-blue">
                        Submit now
                      </button>
                      {/* <div
                        class="error mt-3 alert alert-danger text-left mb-0"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        class="success mt-3 alert alert-success text-left mb-0"
                        style={{ display: "none" }}
                      ></div>
                      <div class="loader" style={{ display: "none" }}>
                        <img alt="loader" src="loader.gif" />
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
{
  /* <!-- Custom Fields --> */
}
//   <input type="hidden" name="url" value="" />
//   <input type="hidden" name="domain" value="" />
//   <input
//     type="hidden"
//     name="subject"
//     value="Contact Us Form ()"
//   />
//   <input
//     type="hidden"
//     name="price"
//     data-name="price"
//     value="$59"
//   />
//   <input
//     type="hidden"
//     name="title"
//     data-name="title"
//     value=""
//   />
//   <input
//     type="hidden"
//     name="pkgctgry"
//     data-name="pkgctgry"
//     value="Logo"
//   />
//   <input
//     type="hidden"
//     name="pkgtitle"
//     data-name="pkgtitle"
//     value="Logo Special"
//   />

//   {/* <!--PPC parameters --> */}
//   <input type="hidden" name="keyword" value="" />
//   <input type="hidden" name="matchtype" value="" />
//   <input type="hidden" name="msclkid" value="" />
//   <input type="hidden" name="gclid" value="" />
{
  /* // <!--End PPC paramters --> */
}
