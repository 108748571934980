import React from 'react'
import contactUsImg from "../../../assest/images/contact-us-banner-img.png"
export const InfoSection = () => {
  return (
    <div>
          <section className="inner-banner content-writing-banner contact-banner">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
                  <h6>WE ARE AT YOUR SERVICE</h6>
                  <h1>Fostering successful <br/> digital relationships</h1>
                  <p>We love taking on exciting new projects for aspiring businesses and growth-oriented clients. Get in touch with us today for a free strategic consultation!</p>
               </div>
               <div className="col-md-7 text-right">
                  <img src={contactUsImg} alt="contactUsImg"/>
               </div>
            </div>
            <h3>creative</h3>
         </div>
      </section>
    </div>
  )
}
