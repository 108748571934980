import React from 'react';
import StationaryBanner from "../../../assest/images/stationary/stationary-banner.jpg"

export const InfoSectionStationary = () => {
  return (
    <div>
           <section class="inner-banner stationary-banner">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <h6>CUSTOM STATIONERY LAYOUTS</h6>
                  <h1>Symbols of Class &amp; Character</h1>
                  <p>Simple, original and elegant designs to tangibly represent your business in everyday interactions.</p>
               </div>
               <div class="col-md-7">
                  <img src={StationaryBanner} alt="StationaryBanner"/>
               </div>
            </div>
            <h3>creative</h3>
         </div>
      </section>
    </div>
  )
}
