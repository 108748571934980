import React from "react";
import './App.css';

import {
  Route,
  Routes,
} from "react-router-dom";
// import { Home } from "./component/home";
import { LandingPage } from "./layout/router";
import { HomeComp } from "./layout/router/main/home";
import { WorkPortfolio } from "./layout/router/main/portfolio";
import { PricingPackages } from "./layout/router/main/packages";
import { AboutUs } from "./layout/router/main/aboutus";
import { ContactUs } from "./layout/router/main/contact";
import { LogoDesign } from "./layout/router/main/logodesign";
import { WebsiteDetails } from "./layout/router/main/website";
import { Stationary } from "./layout/router/main/stationary";
import { Videos } from "./layout/router/main/videos";
import { Seo } from "./layout/router/main/seo";
import { ContentWriter } from "./layout/router/main/contentwriter";
import { SocialMedia } from "./layout/router/main/social";
import { LogoBreif } from "./layout/router/order/logobrief";
import { Payment } from "./layout/router/payment";

function App() {

  return (
    <div>
     <Routes>
      <Route path="/" element={<HomeComp/>} />
      <Route path="/lp" element={<LandingPage/>} />
      <Route path="/portfolio" element={<WorkPortfolio/>}/>
      <Route path="/packages" element={<PricingPackages/>}/>
      <Route path="/about" element={<AboutUs/>}/>
      <Route path="/contactus" element={<ContactUs/>}/>
      <Route path="/logo-design" element={<LogoDesign/>}/>
      <Route path="/website-details" element={<WebsiteDetails/>}/>
      <Route path="/stationary" element={<Stationary/>}/>
      <Route path="/videos" element={<Videos/>}/>
      <Route path="/seo" element={<Seo/>}/>
      <Route path="/content-writer" element={<ContentWriter/>}/>
      <Route path="/social" element={<SocialMedia/>}/>
      <Route path="/order/logo-breif/:id" element={<LogoBreif/>}/>
      <Route path="/order/payment/:id" element={<Payment/>}/>
     </Routes>
    </div>
  );
}

export default App;
