import React from 'react';
import { MainFooter } from '../../../mainFooter';
import { MainHeader } from '../../../header';
import { DescSection, InfoSectionContent } from '../../../../component/contentwriting';

export const ContentWriter = () => {
  return (
    <div>
        <MainHeader/>
        <InfoSectionContent/>
        <DescSection/>
      <MainFooter/>
    </div>
  )
}
