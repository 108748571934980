import React from 'react'
import video_animation from "../../../assest/images/video-animation/video-animation-bannerImg.png";

export const IntroSectionVideo = () => {
  return (
    <div>
        
      <section className="inner-banner video-animation-banner">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
                  <h6>INTERACTIVE ANIMATED VIDEOS WITH</h6>
                  <h1>Awesome Studio Quality Animations</h1>
                  <p>Our imaginative animators make fascinating animated videos with engaging concepts to keep your audience captivated.</p>
               </div>
               <div className="col-md-7">
                  <img src={video_animation} alt="video_animation"/>
               </div>
            </div>
            <h3>creative</h3>
         </div>
      </section>
    </div>
  )
}
