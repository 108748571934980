import React, { useState, useEffect } from "react";
import axios from "axios";
import "../router/main/mainindex.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import popup from "../../assest/images/popop-img.png";
import { useNavigate } from "react-router-dom";
import {
  logoPackages
} from "../../data/portfolio";

export const ModalSection = ({open,onCloseModal, getData}) => {
   console.log("getDatagetDatagetData", getData);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const navigate = useNavigate();

  
  // http://development.tgcrm.net/api/form_submission?brand_key=135688
  const [response, setResponse] = useState(null);

  const handleSubmitFunc = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = 'https://development.tgcrm.net/api/form_submission?brand_key=135688';
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const formDataString = new URLSearchParams(formData).toString();

      const response = await axios.post(apiUrl, formDataString, config);
      if(response.status == 200){
        // navigate(`/order/logo-breif/${getData.id}`);
        console.log(response.data.data.id)
        let Domain_url=`https://www.gotologoexperts.com/order/logo_brief.php?tkn=${response.data.data.id}`
        console.log(Domain_url);
        // window.location = Domain_url;
      }
      console.log("responseresponseresponse",response);
    } catch (error) {
      console.error("Error:", error);
    }
      return false;
  };
//   console.log("response",response);
  return (
    <div className="modal_section">
      <Modal open={open} onClose={onCloseModal}>
      <div>
        <div class="modal-body">
          <div class="popup-bann">
            <img src={popup} alt="popup" />
          </div>
          <div class="popup-content">
            <h2>Get Started Now</h2>
            <p>
              Providing you the perfect solution for your business needs. Let's
              work together and unlock doors to success.
            </p>
            <form onSubmit={handleSubmitFunc}>
            
              <div class="row">
                <div class="col-md-6">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    required
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />

                  <input
                    type="email"
                    class="email"
                    name="email"
                    placeholder="Your Email"
                    required
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />

                  <input
                    type="number"
                    class="number"
                    name="phone"
                    minlength="10"
                    maxlength="12"
                    placeholder="Your Phone"
                    required
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  />
                </div>
                <div class="col-md-6">
                  <textarea
                    autocomplete="nope"
                    name="message"
                    placeholder="To help us understand better, enter a brief description about your project."
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  ></textarea>
                </div>
                <div class="col-md-12 text-center pop-btn-box">
                  <button
                    type="submit"
                    class="btn btn-blue"
                  //   name="submit"
                  //   value="Submit Now"
              
                  >Submit Now</button>
               
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

 
   
      </Modal>
    </div>
  );
};
  {/* <!-- Custom Fields --> */}
//   <input type="hidden" name="url" value="" />
//   <input type="hidden" name="domain" value="" />
//   <input type="hidden" name="subject" value="" />
//   <input type="hidden" name="price" data-name="price" value="" />
//   <input type="hidden" name="title" data-name="title" value="" />
//   <input
//     type="hidden"
//     name="pkgctgry"
//     data-name="pkgctgry"
//     value=""
//   />
//   <input
//     type="hidden"
//     name="pkgtitle"
//     data-name="pkgtitle"
//     value=""
//   />

//   {/* <!--PPC parameters --> */}
//   <input type="hidden" name="keyword" value="" />
//   <input type="hidden" name="matchtype" value="" />
//   <input type="hidden" name="msclkid" value="" />
//   <input type="hidden" name="gclid" value="" />
  {/* //   <!--End PPC paramters --> */}
//   <div
//   class="error mt-3 alert alert-danger text-left mb-0"
//   style="display: none"
// ></div>
// <div
//   class="success mt-3 alert alert-success text-left mb-0"
//   style="display: none"
// ></div>
// <div class="loader" style="display: none">
//   <img alt="loader" src="loader.gif" />
// </div>