import React from 'react'

export const MainFooter = () => {
  return (
    <div>
         <section class="support-cta">
         <div class="container">
            <p>We can be reached at <a href="<?php echo PHONE_HREF ?>">00000000000</a></p>
            <p>or you can email us at <a href="<?php echo EMAIL_HREF ?>">demo@gmail.com</a></p>
         </div>
      </section>

      <section class="logoBar">
         <div class="container">
            <img src="assets/images/logoBar.png" alt=""/>
         </div>
      </section>

      <footer>
         <div class="container">
            <div class="row">
               <div class="col-md-4">
                  <a href=""><img src="assets/images/logo-black.png" alt=""/></a>
                  <p>Go To Logo Experts is a premier custom logo design company established with the goal to bring brands at the top charts by creating awe-inspiring personalities. </p>
                  <a href="javascript:;" class="address">
                     <i class="fas fa-map-pin"></i>
                  </a>
                  <ul class="term">
                     <li><a href="term-and-conditions.php">Terms &amp; Conditions</a></li>
                     <li><a href="privacy-policy.php">Privacy Policy</a></li>
                  </ul>
               </div>
               <div class="col-md-2 cus-mar1 col-xs-6">
                  <h4>Quick Links</h4>
                  <ul>
                     <li><a href="/">Home</a></li>
                     <li><a href="about.php">About Us</a></li>
                     <li><a href="portfolio.php">Work</a></li>
                     <li><a href="our-packages.php">Pricing</a></li>
                     <li><a href="contact.php">Contact Us</a></li>
                  </ul>
               </div>
               <div class="col-md-3 col-xs-6 cus-mar2">
                  <h4>Services</h4>
                  <ul>
                     <li><a href="logo-design.php">Logo Design</a></li>
                     <li><a href="website-design.php">Website</a></li>
                     <li><a href="stationery.php">Stationery</a></li>
                     <li><a href="video-animation.php">Video Animation</a></li>
                     <li><a href="seo.php">Search Engine Optimization</a></li>
                     <li><a href="content-writing.php">Content Writing</a></li>
                     <li class="last"><a href="social-media-design.php">Social Media Design</a></li>
                  </ul>
               </div>
               <div class="col-md-3 ftr-last">
                  <h4>Contact</h4>
                  <ul>
                     <li><a href="<?php echo PHONE_HREF ?>"><i class="fas fa-mobile-alt"></i>000000000</a></li>
                     <li><a href="<?php echo EMAIL_HREF ?>"><i class="far fa-envelope"></i>demo@gmail.com</a></li>
                  </ul>
                  <ul class="ftr-socialicons">
                     <li class="first"><a href="https://www.facebook.com/gotologoexperts"><i class="fab fa-facebook-f"></i></a></li>
                     <li><a href="https://www.linkedin.com/company/91106214/admin/?feedType=following"><i class="fab fa-linkedin"></i></a></li>
                     <li class="last"><a href="https://www.instagram.com/gotologoexpertsofficial/?next=%2FLogoexperts@123"><i class="fab fa-instagram"></i></a></li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="copy">
            <div class="container">
               <p>Copyright &copy;  Go To Logo Experts. All Rights Reserved.</p>
            </div>
         </div>
      </footer>

    </div>
  )
}
