import React from 'react';
import contentWriter from "../../../assest/images/content-writing/content-writing-banner-img.png"

export const InfoSectionContent = () => {
  return (
    <div>
        
      <section class="inner-banner content-writing-banner">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <h6>POWERFUL CONTENT CREATION</h6>
                  <h1>Articulating Creative &amp; Distinctive Ideas</h1>
                  <p>Our highly-qualified and proficient content writers produce content that is easily understandable, and simply compelling for your target audiences.</p>
               </div>
               <div class="col-md-7">
                  <img src={contentWriter} alt="contentWriter"/>
               </div>
            </div>
            <h3>creative</h3>
         </div>
      </section>
    </div>
  )
}
