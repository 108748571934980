import React from 'react';
import premSoicla from "../../../assest/images/social-media-design/prem-social.png"
export const DescSectionSocial = () => {
  return (
    <div>
   <section class="premium-txt prem-social">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <img src={premSoicla} alt="premSoicla" class=""/>
               </div>
               <div class="col-md-7">
                  <h6>BOOST VISIBILITY ON</h6>
                  <h2>Social Media</h2>
                  <p>For effective social media marketing (SMM), Go To Logo Experts has savvy social media experts on board who have extensive experience in promoting brand outreach campaigns through carefully devised marketing campaigns across various social media platforms. We highly recommend SMM to be used in conjunction with our associated SME and SMO services for successful digital marketing.</p>
                  <p>We strategize the complete plan to control activities on different social media platforms. As a modern and persuasive means of communication, social media enable brands to reach the clients at their homes.</p>
                  {/* <div class="moreContnt" style="display: none;">
                     <p>We make your social media designs for all the above mentioned social media platforms. In short, you can hire our social media design services to create the following:</p>
                     <p>Custom Cover Page, Profile Page, Welcome Page and Theme Designs for Facebook, Twitter, YouTube, LinkedIn, Google Plus or any other platform you want to represent yourself on.</p>
                     <h5>STOCK PHOTOS</h5>
                     <p>We use premium quality stock photos with high resolution to make the social media design appealing ultimately making your social media page more reliable.</p>
                     <h5>FREE REVISION (UNLIMITED)</h5>
                     <p>We also provide you with unlimited revisions so that you can exactly get the social media design you wish for.</p>
                     <h5>100% MONEY BACK GUARANTEE</h5>
                     <p>We offer you 100% money back guarantee if we fail to create appealing designs or do not fulfil your expectations.</p>
                  </div> */}
                  <a href="javascript:;" class="btn btn-grey">Read More</a>
               </div>
            </div>
         </div>

      </section>
    </div>
  )
}
