import React from 'react'
import premContent from "../../../assest/images/content-writing/prem-content.jpg"
export const DescSection = () => {
  return (
    <div>
       
      <section class="premium-txt prem-content">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <img src={premContent} alt="premContent" class="premContent"/>
               </div>
               <div class="col-md-7">
                  <h6>WE MAKE</h6>
                  <h2>Content Creation Simple!</h2>
                  <p>At Go To Logo Experts, we are bring you premium content writing solutions that will help you put your brands in the limelight. Experienced optimizers, writers and link-builders are always at your disposal to make things happen exactly the way you want, or even better!</p>
                  <h5>Why choose Us As Your Content Writing services?</h5>
                  <p class="margin-zero">
                     We lay great emphasis on timely turnaround and do not miss any committed deadline. We believe in serving our customers at optimum level and ensure to consider every major and minor aspect with great care since project turnaround time is a major and significant concern. We guarantee you will not be disappointed with our content writing services.
                  </p>
                  {/* <div class="moreContnt" style="display: none;">
                     <h5>GUARANTEED SUPREME QUALITY</h5>
                     <p>It is quality work that matters most for us as well as for you, therefore, we have highly skilled writers specializing in different writing genres who produce only supreme quality content writing services for every client we serve.</p>
                     <h5>SEO OPTIMIZED CONTENT</h5>
                     <p>Our writers are always updated with the latest happenings pertaining to the criterion and algorithms of major online search engines i.e. Google, Yahoo!, MSN and others. Therefore, our writers belonging to all genres have crystal-clear idea of the latest and productive practices regarding SEO optimized content and produce content in accordance.</p>
                     <h5>COMPLETE RANGE OF ONLINE CONTENT WRITING SERVICES</h5>
                     <p>We are a proud one-stop solution for all sorts of content writing genres possible. The major content writing genres are as follows:</p>
                     <ul>
                        <li>Content for Website Pages</li>
                        <li>Sales Letters</li>
                        <li>Copywriting</li>
                        <li>Informative Articles</li>
                        <li>Blog Posts</li>
                        <li>Corporate Blogs</li>
                        <li>Product Descriptions</li>
                        <li>Product Reviews</li>
                        <li>eBooks</li>
                        <li>Press Releases</li>
                        <li>Newsletters</li>
                        <li>Email Marketing Campaigns</li>
                     </ul>
                     <h5>USER-ORIENTED CONTENT</h5>
                     <p>We hate reading awfully keyword-stuffed content and practice the same when we work on your content writing service project. A piece of content getting no appreciation by readers is not only worthless but also extremely and irreparably damaging to the reputation of publishing source.</p>
                     <p>Keeping the requirements of SEO regarding content writing services, we strategically create content which can serve to the search engines and most importantly get appreciated and shared by the readers.</p>
                     <h5>HIGH SOCIAL MEDIA SHARES</h5>
                     <p>Number of social media shares by readers is a clear-cut success measure and we enhance your social media shares by creating highly appealing content where it becomes irresistible for readers to share it on social media.</p>
                     <h5>UNLIMITED REVISIONS</h5>
                     <p>While working on your content project, we keep you updated with project’s progress status and you can ask for unlimited revisions to make any changes you find necessary.</p>
                     <h5>HOW IT WORKS?</h5>
                     <p>Get Your Content Written by Our Experts</p>
                     <ul>
                        <li>Publish It</li>
                        <li>Get Good Rankings in SERPs Fast</li>
                        <li>Share on Social Media</li>
                        <li>Get Readers</li>
                        <li>Achieve Your Call-to-Action</li>
                     </ul>
                  </div> */}
                  <a href="javascript:;" class="btn btn-grey">Read More</a>
               </div>
            </div>
         </div>
  
      </section> 
    </div>
  )
}
