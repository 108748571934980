import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { IntroSection, WebDesc, WebPortfolio } from '../../../../component/website'
import { WebPackages } from '../../../../component/website/webPackages'

export const WebsiteDetails = () => {
  return (
    <div>
        <MainHeader/>
        <IntroSection/>
        <WebDesc/>
        <WebPortfolio/>
        <div style={{margin: "60px 0px"}}>
        <WebPackages/>
        </div>
        <MainFooter/>
    </div>
  )
}
