import React from 'react';
import premiumStationary from "../../../assest/images/stationary/premium-stationary.jpg"
export const DesignSolution = () => {
  return (
    <div>
      <section class="premium-txt">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <img src={premiumStationary} alt="premiumStationary" class="mar-img"/>
               </div>
               <div class="col-md-7">
                  <h6>CUTTING-EDGE DESIGN</h6>
                  <h2>Premium Stationery <br/>
                     Design Solutions
                  </h2>
                  <p>At Go To Logo Experts, we are bring you premium stationery design solutions that will help you put your brands in the limelight. Experienced designers and consultants are always at your disposal to make things happen exactly the way you want, or even better!</p>
                  <p>Your company logo may lose its appeal if it is not fittingly adjusted with your corporate stationery design items, so get professional corporate stationery design services and be shinning among the competition.</p>
                  <h5>WHY HIRE Go To Logo Experts AS YOUR STATIONERY DESIGNS COMPANY?</h5>
                  <p>We have already served a huge client base with fascinating logos, website designs and development and corporate stationery design projects. Go To Logo Experts is complete design solution service, so there is no chance to miss any corner uncovered under our umbrella. Being a complete design studio, we offer professional corporate stationery design as well as personalized business stationery designs. It is the spot where you get a wide-ranging brand package for your company and its further development.</p>
               
                  <a href="javascript:;" class="btn btn-grey">Read More</a>
               </div>
            </div>
         </div>
    
         
      </section>
    </div>
  )
}
