import React, {useEffect} from 'react';
import img1 from "../../../assest/bnr-img1.png"
import img2 from "../../../assest/bnr-img2.png"
import img3 from "../../../assest/bnr-img3.png"
import img4 from "../../../assest/bnr-img4.png"
import img5 from "../../../assest/bnr-img5.png"
import img6 from "../../../assest/bnr-img6.png"
import img7 from "../../../assest/bnr-img7.png"
import Aos from 'aos';
import 'aos/dist/aos.css';

export const BannerSection = () => {


    useEffect(() => {
        Aos.init({
          duration: 1400,
          once: false,
          easing: 'ease-in-sine',
        });
      });

  return (
    <div>
          <section className="main-banenr lazy-background">
        <div className="container lp_banner">
          <div className="col-md-7 bnr-wrpr" data-aos="fade-down" data-aos-duration="1500">
            <div className="banner-heading">
              <h2>Get A Custom</h2>
              <h1>Logo Design <br />
                <span> For Your </span> Business
              </h1>
              <h3>In Just <strong><sup>$</sup>35</strong></h3>
              <p>Sign Up Now & Instantly <strong> Avail 70% Discount </strong> </p>
            </div>


            <div className="main-form">
              <form className="form_submission" id="regForm" method="POST">



                <div className="frm-grup-cta">
                  <div className="form-group  tab tab-1" style={{ display: "block" }}>
                    <input type="text" className="form-control" name="name" placeholder="Enter Your Name"
                      required="" />
                  </div>

                  <div className="frm-grup-cta-btn lp">
                    <button className="btn bnr-btn tab-1-btn next" type="button" 
                      style={{ display: "inline", fontWeight: "800" }}>Next</button>

                  </div>
                </div>
              </form>
            </div>

          </div>

          <div className="banner-images col-md-6 ">
            <div className="row align-items-center no-gutters">
              <div className="col-md-3">
                <img src={img1} alt="img1" width={"auto"} height={"auto"} />
              </div>
              <div className="col-md-3">
                <img src={img2}  alt="img2" width={"auto"} height={"auto"} />
                <img src={img5}  alt="img5" width={"auto"} height={"auto"} />
              </div>
              <div className="col-md-3">
                <img className="sm-img" src={img3}  alt="img3" width={"auto"} height={"auto"} />
                <img className="sm-img" src={img6}  alt="img6" width={"auto"} height={"auto"} />
              </div>
              <div className="col-md-3">
                <img className="sm-img" src={img4}  alt="img7" width={"auto"} height={"auto"} />
                <img className="sm-img" src={img7}  alt="img7" width={"auto"} height={"auto"} />
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}
