import React from 'react';
import soicalMediaBanner from "../../../assest/images/social-media-design/social-media-banner-img.png"
export const InfoSectionSoical = () => {
  return (
    <div>
         <section class="inner-banner social-banner">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <h6>BOOST VISIBILITY ON SOCIAL MEDIA</h6>
                  <h1>Distinguished Social Media Marketing</h1>
                  <p>Helping brands with different aspects of their social media marketing strategy by deploying result-driven services.</p>
               </div>
               <div class="col-md-7">
                  <img src={soicalMediaBanner} alt="soicalMediaBanner"/>
               </div>
            </div>
            <h3>creative</h3>
         </div>
      </section>
    </div>
  )
}
