export const logoPackages = [
    {
      id: 1,
      heading: "Logo Basic",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$59",
      list: [
        "4 Original Logo Concepts",
        "2 Dedicated Logo Designer",
        "4 Revisions",
        "With Grey Scale Format",
        "Free Icon Design",
        "Formats: JPEG Only",
        "24 - 48 Business Hours Turn Around Time",
        "100% Satisfaction",
        "100% Ownership Rights",
        "Money Back Guarantee",
        "Dedicated Account Manager"
      ]
    },
    {
      id: 2,
      heading: "Logo Professional",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$199",
      list: [
        "12 Original Logo Concepts",
        "4 Dedicated Logo Designer (Industry Specific)",
        "Unlimited Revisions",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "Email Signature Design",
        "With Grey Scale Format",
        "Free Icon Design",
        "Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG",
        "24 - 48 Business Hours Turn Around Time",
        "100% Satisfaction",
        "100% Ownership Rights",
        "Money Back Guarantee",
        "Dedicated Account Manager",
        "24/7 Support (Email, Chat, Call, SMS, Whatsapp)"
      ]
    },
    {
      id: 3,
      heading: "Logo Elite",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$399",
      list: [
        "Unlimited Original Logo Concepts",
        "8 Dedicated Logo Designer (Industry Specific)",
        "Unlimited Revisions",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "500 Business Cards",
        "Email Signature Design",
        "With Grey Scale Format",
        "Free Icon Design",
        "Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG",
        "24 - 48 Business Hours Turn Around Time",
        "100% Satisfaction",
        "100% Ownership Rights",
        "Money Back Guarantee",
        "Dedicated Account Manager"
      ]
    },
    {
      id: 4,
      heading: "Logo Infinite",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1249",
      list: [
        "Unlimited Original Logo Concepts",
        "12 Dedicated Logo Designer (Industry Specific)",
        "Unlimited Revisions",
        "5 Page Informative Website",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "Brand Book",
        "500 Business Cards",
        "500 Letterheads",
        "Email Signature Design",
        "Social Media Designs (Facebook, Twitter, Instagram)",
        "2 Sided Flyer OR Bi-Fold Brochure Design",
        "With Grey Scale Format",
        "Free Icon Design",
        "Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG",
        "24 - 48 Business Hours Turn Around Time",
        "100% Satisfaction",
        "100% Ownership Rights",
        "Money Back Guarantee",
        "Dedicated Account Manager"
      ]
    },
    {
      id: 5,
      heading: "Mascot Logo",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$599",
      list: [
        "2 Mascot Logo Concepts",
        "Unlimited Revisions",
        "Hand drawn Sketch",
        "Presentation on mockup",
        "24 - 48 Business Hours Turn Around Time",
        "File Formats: Ai,Eps,Png,Jpeg,pdf",
        "100% Satisfaction",
        "100% Ownership Rights",
        "Money Back Guarantee",
        "Dedicated Account Manager"
      ]
    },
    {
      id: 6,
      heading: "3D Logo",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$349",
      list: [
        "3 Unique 3D Logo Concepts",
        "Light Effects and VFX",
        "Fully Rendered",
        "Multiple 3D Angles",
        "3 Dedicated Designers",
        "72 Business Hours Turn Around Time",
        "UNLIMITED Revisions",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Unique Design Guarantee",
        "100% Money Back Guarantee*"
      ]
    }
  ];

  export let websitePackages = [
    {
      id: 1,
      heading: "Basic Website",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$499",
      list: [
        "3 Page Website",
        "Custom Layout Design",
        "Contact/Query Form",
        "1 Banner Design",
        "2 Stock Photos",
        "FREE Favicon Design",
        "Cross Browser Compatible",
        "Complete W3C Certified HTML",
        "Website Initial Concepts in 48 Hours",
        "Complete Design & Deployment",
        "Complete Source Files",
        "Dedicated Project Manager",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee",
        "Value Added Services",
        "Mobile Responsive will be Additional $200*",
        "CMS will be Additional $250*",
        "*NO MONTHLY OR ANY HIDDEN FEE*"
      ]
    },
    {
      id: 2,
      heading: "Startup Website",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$899",
      list: [
        "5 Page Website",
        "Custom Layout Design",
        "Contact/Query Form",
        "3 Banner Designs",
        "5 Stock Photos",
        "FREE Favicon Design",
        "FREE Google Friendly Sitemap",
        "Unlimited Revisions",
        "Complete W3C Certified HTML",
        "Website Initial Concepts in 48 Hours",
        "Complete Design & Deployment",
        "Complete Source Files",
        "Dedicated Project Manager",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee",
        "Value Added Services",
        "Mobile Responsive will be Additional $200*",
        "CMS will be Additional $250*",
        "*NO MONTHLY OR ANY HIDDEN FEE*"
      ]
    },
    {
      id: 3,
      heading: "Professional Website",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1499",
      list: [
        "Up to 10 Unique Pages Website",
        "CMS /Admin Panel Integration",
        "5+ Stock Photos & Banner Designs",
        "FREE Social Media Integration",
        "FREE Favicon Design",
        "FREE Google Friendly Sitemap",
        "Unlimited Revisions",
        "Cross Browser Compatible",
        "Complete W3C Certified HTML",
        "Website Initial Concepts in 48 Hours",
        "Complete Design & Deployment",
        "Custom, Interactive & Dynamic Web Design",
        "Industry specified Team of Expert Designers and Developers",
        "Complete Source Files",
        "Dedicated Project Manager",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee",
        "Value Added Services",
        "Mobile Responsive will be Additional $200*",
        "CMS will be Additional $250*",
        "*NO MONTHLY OR ANY HIDDEN FEE*"
      ]
    },
    {
      id: 4,
      heading: "E-Commerce Website",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1799",
      list: [
        "Custom Ecommerce Website",
        "Up to 200 Products",
        "CMS /Admin Panel Integration",
        "Fully Mobile Responsive",
        "Shopping Cart Integration",
        "Payment Gateway Integration",
        "Product Listing & Management",
        "Order Management & Tracking",
        "Banner Designs",
        "Unlimited Revisions",
        "FREE Social Media Integration",
        "FREE Favicon Design",
        "FREE Google Friendly Sitemap Search Engine Submission",
        "Complete W3C Certified HTML",
        "Industry specified Team of Expert Designers and Developers",
        "Complete Deployment",
        "Complete Source Files",
        "Dedicated Project Manager",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee",
        "*NO MONTHLY OR ANY HIDDEN FEE*"
      ]
    },
    {
      id: 5,
      heading: "Corporate Website",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$3999",
      list: [
        "Up to 15 Unique Pages Website",
        "Custom Made, Interactive & Dynamic Design",
        "Customized WordPress or PHP Development",
        "Fully Mobile Responsive",
        "Interactive Sliding Banners",
        "Up to 10 Custom Made Banner Designs",
        "10 Stock Images",
        "Unlimited Revisions",
        "Content Management System",
        "Online Signup Area (For Newsletters, Offers etc.)",
        "Search Bar",
        "Live Feeds of Social Networks integration (Optional)",
        "Google Friendly Sitemap",
        "Search Engine Submission",
        "Cross Browser Compatible",
        "Complete W3C Certified HTML",
        "Industry specified Team of Expert Designers and Developers",
        "Complete Deployment",
        "Complete Source Files",
        "Dedicated Project Manager",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee",
        "*NO MONTHLY OR ANY HIDDEN FEE*"
      ]
    },
    {
      id: 6,
      heading: "Platinum Website",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$6999",
      list: [
        "Up to 20 Unique Pages Website",
        "Custom Made, Interactive, Dynamic & High-End Design",
        "Customized WordPress or PHP Development",
        "Fully Mobile Responsive",
        "Interactive Sliding Banners",
        "Up to 15 Custom Made Banner Designs",
        "15 Stock Images",
        "Unlimited Revisions",
        "Content Management System",
        "Online Appointment/Booking/Scheduling/Online Ordering Integration (Optional)",
        "Online Payment Integration (Optional)",
        "Multi Lingual (Optional)",
        "Custom Dynamic Forms (Optional)",
        "Signup Area (For Newsletters, Offers etc.)",
        "Search Bar",
        "Live Feeds of Social Networks integration (Optional)",
        "Google Friendly Sitemap",
        "Search Engine Submission",
        "Cross Browser Compatible",
        "Complete W3C Certified HTML",
        "Industry specified Team of Expert Designers and Developers",
        "Complete Deployment",
        "Complete Source Files",
        "Dedicated Project Manager",
        "100% Ownership Rights",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee",
        "*NO MONTHLY OR ANY HIDDEN FEE*"
      ]
    }
  ];


  
  export let videoPackages = [
    {
      id: 1,
      heading: "Teaser",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$499",
      list: [
        "30sec Duration",
        "Script Writing",
        "Custom Artwork",
        "Background Music",
        "HD Format Video",
        "Dedicated Support",
        "100% Ownership Rights",
        "100% Money-back Guarantee",
      ]
    },
    {
      id: 2,
      heading: "Startup",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$799",
      list: [
        "30sec Duration",
        "Script Writing",
        "Professional Voice-over & SFX",
        "Hand-drawn Illustrations",
        "Unlimited Revisions",
        "HD Format Video",
        "Dedicated Support",
        "100% Ownership Rights",
        "100% Money-back Guarantee",
      ]
    },
    {
      id: 3,
      heading: "Classic",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$999",
      list: [
        "30sec Duration",
        "Script Writing",
        "Professional Voice-over & SFX",
        "Custom 2D Characters",
        "Unlimited Revisions",
        "HD Format Video",
        "Dedicated Support",
        "100% Ownership Rights",
        "100% Money-back Guarantee",
      ]
    },
    {
      id: 4,
      heading: "3D Video",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$3999",
      list: [
        "2 3D Object Models",
        "Unlimited Storyboard Revisions",
        "Professional Voice-Over",
        "Background Music",
        "Exotic Animations",
        "HD Video Format",
        "6 Weeks Delivery Time",
        "100% Ownership Rights",
        "100% Money-back Guarantee",
      ]
    }
  ];
  
  export let brandingPackages = [
    {
      id: 1,
      heading: "Branding Startup",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$499",
      list: [
        "Logo Design",
        "6 Unique Logo Concepts",
        "FREE Icon",
        "FREE Grayscale Copy",
        "Unlimited Revisions",
        "100% Ownership Right",
        "AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats",
        "Print Media",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "Website Design",
        "5 Pages Custom Business Website",
        "Complete Deployment",
      ]
    },
    {
      id: 2,
      heading: "Branding Plus",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$999",
      list: [
        "Logo Design",
        "12 Unique Logo Concepts",
        "FREE Icon",
        "FREE Grayscale Copy",
        "Unlimited Revisions",
        "100% Ownership Right",
        "AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats",
        "Print Material",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "Flyer Design / Label Design",
        "Website Design",
        "Custom 5 Pages Professional Website",
        "Content Management System (WordPress)",
        "Responsive Design (Mobile Compatible)",
        "Complete Deployment",
        "Social Media Design",
        "Facebook Fanpage Splash Page + Cover Photo",
      ]
    },
    {
      id: 3,
      heading: "Branding Classic",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1499",
      list: [
        "Logo Design",
        "12 Unique Logo Concepts",
        "FREE Icon",
        "FREE Grayscale Copy",
        "Unlimited Revisions",
        "100% Ownership Right",
        "AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats",
        "Print Material",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "Any Product of your Choice",
        "Bi-fold/Tri-fold Brochure / Menu Card / Corporate Profile",
        "Website Design",
        "10+ Page Website",
        "Content Management System (WordPress/Joomla)",
        "Complete Deployment",
        "Search Engine Submission",
        "Google, Bing, Yahoo and More",
        "Social Media Design",
        "Facebook Fanpage Splash Page + Cover Photo",
        "Twitter Background Design",
        "Google+ Profile Banner",
      ]
    },
    {
      id: 4,
      heading: "Branding Ultimate",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1999",
      list: [
        "Logo Design",
        "Infinite Unique Logo Concepts",
        "FREE Icon",
        "FREE Grayscale Copy",
        "Unlimited Revisions",
        "100% Ownership Right",
        "AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats",
        "Print Material",
        "Stationery Design (Business Card, Letterhead, Envelope)",
        "500 Business Cards, Letterhead, Envelop Prints",
        "Any Product of your Choice",
        "Bi-fold/Tri-fold Brochure / Menu Card / Corporate Profile",
        "Website Design",
        "10+ Page Website",
        "Content Management System (WordPress/Joomla)",
        "Responsive Design (Mobile Compatible)",
        "Complete Deployment",
        "Social Media Design",
        "Facebook Fanpage Splash Page + Cover Photo",
        "Twitter Background Design",
        "Google+ Profile Banner",
        "Complete Storyboard, VoiceOver & Animation",
      ]
    }
  ];

  
  export let seoPackages = [
    {
      id: 1,
      heading: "Basic",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$299",
      list: [
        "10 Keywords Optimization",
        "4 Keywords in top 10",
        "Search Engine Submissions",
        "2 Blogs Writing",
        "4 Blog Posting Links",
        "10 Blogs Social Bookmarking Links",
        "1 Press Release Writing",
        "2 Press Release Links",
      ]
    },
    {
      id: 2,
      heading: "Standard",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$499",
      list: [
        "25 Keywords Optimization",
        "10 Keywords in Top 10",
        "Search Engine Submissions",
        "6 Blogs Writing",
        "12 Blog Posting Links",
        "20 Blogs Social Bookmarking Links",
        "2 Press Release Writing",
        "4 Press Release Links",
      ]
    },
    {
      id: 3,
      heading: "Professional",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$799",
      list: [
        "50 Keywords Optimization",
        "20 Keywords in Top 10",
        "14 Blogs Writing",
        "28 Blog Posting Links",
        "30 Blogs Social Bookmarking Links",
        "4 Press Release Writing",
        "8 Press Release Links",
        "4 Guest Blogging Links",
      ]
    },
    {
      id: 4,
      heading: "Enterprise",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1499",
      list: [
        "100 Keywords Optimization",
        "40 Keywords in Top 10",
        "30 Blogs Writing",
        "60 Blog Posting Links",
        "40 Blogs Social Bookmarking Links",
        "8 Press Release Writing",
        "8 Press Release Links",
        "8 Guest Blogging Links",
      ]
    }
  ];

  export let socialPackages = [
    {
      id: 1,
      heading: "Bronze - Monthly",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$397",
      list: [
        "Optimize FB Page",
        "1 Social Cover",
        "Engagement",
        "Reviews",
        "Check-ins",
        "Posts",
        "3 Posts per Week",
        "1 Facebook Event",
        "Monthly Reporting",
        "Ad spend - Up to $50",
      ]
    },
    {
      id: 2,
      heading: "Silver- Monthly",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$597",
      list: [
        "Optimize FB Page",
        "1 Social Cover",
        "Engagement",
        "Reviews",
        "Check-ins",
        "Posts",
        "Shares",
        "Mentions",
        "Invite to Like",
        "Inbox Responses",
        "4 Posts per Week",
        "2 Facebook Events",
        "Monthly Reporting",
        "Ad Spend - Up to $65",
      ]
    },
    {
      id: 3,
      heading: "Gold- Monthly",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$997",
      list: [
        "Optimize FB Page",
        "1 Social Cover",
        "Engagement",
        "Reviews",
        "Check-ins",
        "Posts",
        "Shares",
        "Mentions",
        "Invite to Like",
        "Inbox Responses",
        "5 Posts per Week",
        "2 Facebook Events",
        "1 Lead Generation Campaign",
        "Monthly Reporting",
        "Ad Spend - Up to $120",
      ]
    },
    {
      id: 4,
      heading: "Platinum- Monthly",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$1497",
      list: [
        "Optimize FB Page",
        "1 Social Cover + Holiday Covers",
        "Engagement",
        "Reviews",
        "Check-ins",
        "Posts",
        "Shares",
        "Mentions",
        "Invite to Like",
        "Inbox Responses",
        "6 Posts per Week",
        "2 Facebook Events",
        "1 Lead Generation Campaign",
        "Monthly Reporting",
        "Ad Spend - Up to $170",
      ]
    }
  ];
  