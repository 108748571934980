import React, { useState } from "react";
import black_logo from "../../assest/images/logo-blacks.png";
import phonecrice from "../../assest/images/phone-circle.png";
import { NavLink } from "react-router-dom";
// import { Modal } from "../../component/home/check";
import { ModalSection } from "../modal";

export const MainHeader = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

   let formatData={
     id: 0,
     packageType: "logo"
   };


  return (
    <div>
      <header>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3">
              <NavLink to="/" className="logo">
                <img src={black_logo} alt="black_logo" />
              </NavLink>
            </div>

            <div className="col-md-9">
              <nav className="navbar-expand-md main-menu">
                <ul className="menu main_header">
                  <li>
                    <NavLink to="/">
                      <span>01</span>Home
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <div className="section_link">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                          <li className="nav-item dropdown ">
                            <a
                              className="nav-link dropdown-toggle"
                              href="javascript:"
                              id="navbarDropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span>02</span>Services
                            </a>

                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdownMenuLink"
                            >
                              <li>
                                <NavLink to="/logo-design">Logo Design</NavLink>
                              </li>
                              <li>
                                <NavLink to="/website-details">Website</NavLink>
                              </li>
                              <li>
                                <NavLink to="/stationary">Stationery</NavLink>
                              </li>
                              <li>
                                <NavLink to="/videos">Video Animation</NavLink>
                              </li>
                              <li>
                                <NavLink to="/seo">SEO</NavLink>
                              </li>
                              <li>
                                <NavLink to="/content-writer">
                                  Content Writing
                                </NavLink>
                              </li>
                              <li className="last">
                                <NavLink to="/social">
                                  Social Media Design
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <li>
                        <NavLink to="/portfolio">
                          <span>03</span>Work
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/packages">
                          <span>04</span>Pricing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/about">
                          <span>05</span>About
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/contactus">
                          <span>06</span>Contact
                        </NavLink>
                      </li>

                      <li className="phone">
                        <img src={phonecrice} alt="phonecrice" />
                        <a href="<?php echo PHONE_HREF ?>">(855) 535-2384</a>
                      </li>
                    </div>
                    <li className="me-btn">
                      <button onClick={onOpenModal}  className="various btn btn-blue openpopup">Get Started</button>

                     
                      {/* <a data-fancybox="" title="Get Started" data-src="#popupform" href="javascript:;" className="various btn btn-blue openpopup">Get Started</a> */}
                      <a
                        href="javascript:;"
                        className="chat various btn btn-blue"
                      >
                        Live Chat
                      </a>
                    </li>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <ModalSection open={open} onCloseModal={onCloseModal} getData={formatData} />
    </div>
  );
};
