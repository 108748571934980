import React,{useState} from 'react'
import red_logo from "../../../assest/3d-logo1.webp"
import d_logo from "../../../assest/3d-logo2.webp";
import animated from "../../../assest/animated.gif";
import animated2 from "../../../assest/services-02.gif";
import illustrative1 from "../../../assest/illustrative1.webp";
import illustrative2 from "../../../assest/illustrative2.webp";
import iconice1 from "../../../assest/iconic1.webp";
import iconic2 from "../../../assest/iconic2.webp";
import absxtrac1 from "../../../assest/abstract1.webp";
import abstract2 from "../../../assest/abstract2.webp";
let data=[
    {
      id: 1,
      heading: "3D Logo",
      desc:`3D logo designs almost bring the logo to life by enhancing the design's luster. With our abundant 3D logo designs – enjoy the rewards of attracting many clients as your audience will be able to experience the look and feel of your business.`,
      img1: red_logo,
      img2: d_logo,
    },
    {
      id: 2,
      heading: "Animated Logo",
      desc:`Do you want an animated logo? Unique, exceptional, and a little funky that comes as a whole in animated designs. Our team of designers can design animated logos just the way you want them to be. We are experts at adding animated elements to your logo that perfectly blend colors, light, and text.    `,
      img1: animated,
      img2: animated2,
    },
    {
      id: 3,
      heading: "ILLustrative Logo",
      desc:`Illustrative logos provide a modern art-like image of your brand and will serve as a creative and decorative addition to your branding. Additionally, they will portray your brand as trendy and contemporary and have a larger visual impact than traditional logo designs.    `,
      img1: illustrative1,
      img2: illustrative2,
    },
    {
      id: 4,
      heading: "Icoic Logo",
      desc:`We can turn your business ideas into iconic logos by converting them into simple, elegant and appealing elements. Iconic logos are known for their simplicity. We can create simple yet attractive designs to give a unique identity to your brand that will never wipe away from the customer's mind.    `,
      img1: iconice1,
      img2: iconic2,
    },
    {
      id: 5,
      heading: "Abstract Logo",
      desc:`Abstract logo designs also known as smart logos are a mix of pictures, typography, and shapes. Today, many companies rely on abstract logo designs for their branding purposes. Join hands with dummy name to create your abstract logo that reflects an unclear vision of your business.`,
      img1: absxtrac1,
      img2: abstract2,
    },
  ];
  
  let item=[
    {
      id: 1,
      item: "3D Logo"
    },
    {
      id: 2,
      item: "Animated Logo"
    },
    {
      id: 3,
      item: "ILLustrative Logo"
    },
    {
      id: 4,
      item: "Iconic Logo"
    },
    {
      id: 5,
      item: "Abstract Logo"
    },
  ]
  

export const TypeLogo = () => {


    let [selectLogo, setSelectLogo]=useState([]);
    const [activeItem, setActiveItem] = useState(1); // Initialize with 0 for the first item
  
    function getSpecifValue(id){
      
        let respData=data.filter(value => value.id == id)
        console.log(respData);
        setActiveItem(id)
        setSelectLogo(respData)
        
    }
  return (
    <div>
         <section className="typeslogo lazy-background">
        <div className="container">
            <div className="section-heading">
                <h3 className="lp-sec-title" data-aos="fade-down" data-aos-duration="1500">What Type Is Your Logo?</h3>
            </div>
            <div className="row align-items-center">
                <div className="col-md-5">
                    <div className="listtypes" data-aos="fade-right" data-aos-duration="1500">
                        <ul>
                            {item.map(value => {
                                return(
                                    <li  className={value.id == activeItem ? "active" : ""} data-targetit="box-3dlogo" key={value.id} onClick={() =>  getSpecifValue(value.id)}>
                                    <span>{value.id}</span> <a href="javsscript:;" title="3D Logo">{value.item}</a>
                                </li>                                    
                                )
                            })}
                            {/* <li className="active" data-targetit="box-3dlogo">
                                <span>01</span> <a href="javsscript:;" title="3D Logo">3D Logo</a>
                            </li>
                            <li data-targetit="box-animlogo">
                                <span>02</span> <a href="javsscript:;" title="Animated Logo">Animated Logo</a>
                            </li>
                            <li data-targetit="box-illustratelogo">
                                <span>03</span> <a href="javsscript:;" title="Illustrative Logo">Illustrative Logo</a>
                            </li>
                            <li data-targetit="box-iconiclogo">
                                <span>04</span> <a href="javsscript:;" title="Iconic Logo">Iconic Logo</a>
                            </li>
                            <li data-targetit="box-abstlogo">
                                <span>05</span> <a href="javsscript:;" title="Abstract Logo">Abstract Logo</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-md-7">
                {selectLogo.length > 0 ? 
                (
                    // {
                        selectLogo.map(value =>{
                        return(
                            <>
                            <div key={value.id} className={`${value.id == 1 ? 'box-3dlogo' : value.id == 2 ? 'box-animlogo' : value.id == 3 ? 'box-illustratelogo' : value.id == 4 ? 'box-iconiclogo'  : value.id ? "box-abstlogo" : "box-3dlogo" }  typesLogo_details`} data-aos="fade-left" data-aos-duration="1500">

                            <h3>{value.heading}</h3>
                            <p>{value.desc}</p>
                            <div className="logos_alls">
                                <div className="row">
                                    <div className="col-md-6"><img alt="gif_one" className={`${value.id == 2 ? "ani-gif": ""}  `} src={value.img1} width={"auto"} height={"auto"}/>
                                    </div>
                                    <div className="col-md-6"><img alt="gif_two" className=" " src={value.img2} width={"auto"} height={"auto"}/>
                                    </div>
                                </div>
                            </div>
                        </div>        
                        
                        </>
                        )
                    })
                // }
                ) : 
                
                (
                    <div className='box-3dlogo  typesLogo_details' data-aos="fade-left">
                       <div>
                         <h3>3D LOGO</h3>
                         <p>
                         3D logo designs almost bring the logo to life by enhancing the design's luster. With our abundant 3D logo designs – enjoy the rewards of attracting many clients as your audience will be able to experience the look and feel of your business.
                         </p>
                         <div className='logos_alls'>
                         <div className="col-md-6">
                            <img src={red_logo} className=" " alt='red_logo' width={"auto"} height={"auto"}/>
                            </div>
                            <div className="col-md-6">
                            <img src={d_logo} className=" " alt='d_logo' width={"auto"} height={"auto"}/>
                            </div>
                         </div>
                       </div>
                    </div>
                   )}
                    {/* {selectLogo.map(value =>{
                        return(
                            <div key={value.id} className="box-3dlogo  typesLogo_details" data-aos="fade-left" data-aos-duration="1500">

                            <h3>{value.heading}</h3>
                            <p>{value.desc}</p>
                            <div className="logos_alls">
                                <div className="row">
                                    <div className="col-md-6"><img alt="" className=" " src={value.img1}/>
                                    </div>
                                    <div className="col-md-6"><img alt="" className=" " src={value.img2}/>
                                    </div>
                                </div>
                            </div>
                        </div>        
                        )
                    })} */}
                    {/* <div className="box-3dlogo showfirst typesLogo_details" data-aos="fade-left" data-aos-duration="1500">

                        <h3>3D Logo Design</h3>
                        <p>3D logo designs almost bring the logo to life by enhancing the design's luster. With our
                            abundant 3D logo designs – enjoy the rewards of attracting many clients as your audience
                            will be able to experience the look and feel of your business.</p>
                        <div className="logos_alls">
                            <div className="row">
                                <div className="col-md-6"><img alt="" className=" " src={red_logo}/>
                                </div>
                                <div className="col-md-6"><img alt="" className=" " src={d_logo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-animlogo typesLogo_details">
                        <h3>Animated Logos</h3>
                        <p>Do you want an animated logo? Unique, exceptional, and a little funky that comes as a whole
                            in animated designs. Our team of designers can design animated logos just the way you want
                            them to be. We are experts at adding animated elements to your logo that perfectly blend
                            colors, light, and text.</p>
                        <div className="logos_alls">
                            <div className="row">
                                <div className="col-md-6"><img alt="" className="ani-gif  "
                                        src={animated}/></div>
                                <div className="col-md-6"><img alt="" className="ani-gif  "
                                        src={animated2}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="box-illustratelogo typesLogo_details">
                        <h3>Illustrative Logos</h3>
                        <p>Illustrative logos provide a modern art-like image of your brand and will serve as a creative
                            and decorative addition to your branding. Additionally, they will portray your brand as
                            trendy and contemporary and have a larger visual impact than traditional logo designs.</p>
                        <div className="logos_alls">
                            <div className="row">
                                <div className="col-md-6"><img alt="" className=" "
                                        src={illustrative1}/></div>
                                <div className="col-md-6"><img alt="" className=" "
                                        src={illustrative2}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="box-iconiclogo typesLogo_details">
                        <h3>Iconic Logos</h3>
                        <p>We can turn your business ideas into iconic logos by converting them into simple, elegant and
                            appealing elements. Iconic logos are known for their simplicity. We can create simple yet
                            attractive designs to give a unique identity to your brand that will never wipe away from
                            the customer's mind.</p>
                        <div className="logos_alls">
                            <div className="row">
                                <div className="col-md-6"><img alt="" className=" " src={iconice1}/>
                                </div>
                                <div className="col-md-6"><img alt="" className=" " src={iconic2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-abstlogo typesLogo_details">
                        <h3>Abstract Logos</h3>
                        <p>Abstract logo designs also known as smart logos are a mix of pictures, typography, and
                            shapes. Today, many companies rely on abstract logo designs for their branding purposes.
                            Join hands with dummy name to create your abstract logo that reflects an
                            unclear vision of your business</p>
                        <div className="logos_alls">
                            <div className="row">
                                <div className="col-md-6"><img alt="" className=" " src={absxtrac1}/>
                                </div>
                                <div className="col-md-6"><img alt="" className=" " src={abstract2}/>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
