import React from 'react'
import premWebsite from "../../../assest/images/website/prem-website.png"
export const WebDesc = () => {
  return (
    <div>
         <section class="premium-txt prem-website">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <img src={premWebsite} alt="premWebsite"/>
               </div>
               <div class="col-md-7">
                  <h6>PERFECT STARTING POINT TO</h6>
                  <h2>Create your online presence</h2>
                  <p>We Create Highly Engaging Customized Professional Website Designs Which Become Center of Attention to Your Every Visitor Fulfilling Your Desired Goals.</p>
                  <p>Go To Logo Experts is a professional website design company subjected to comprehending and creating lively and goal-driven custom website designs. Our team of professional website designers are highly committed to providing you with cheap and flawless professional website. Design Company in 100% accordance with your needs.</p>
                  <p>We Create Highly Engaging Customized Professional Website Designs Which Become Center of Attention to Your Every Visitor Fulfilling Your Desired Goals.</p>
                  <p>Go To Logo Experts is a professional website design company subjected to comprehending and creating lively and goal-driven custom website designs. Our team of professional website designers are highly committed to providing you with cheap and flawless professional website. Design Company in 100% accordance with your needs.</p>
                  <a href="javascript:;" class="btn btn-grey">Read More</a>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
