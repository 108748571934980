import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { IntroSection, LogoInfo, LogoPackages, LogoPortfolio } from '../../../../component/logoDesign'
import { OwnerShip } from '../../../../component/home'

export const LogoDesign = () => {
  return (
    <div>
        <MainHeader/>
        <IntroSection/>
        <div style={{marginTop: "100px"}}>
        <OwnerShip/>
        </div>
        <LogoInfo/>
        <LogoPortfolio/>
        <div style={{margin: "60px 0px"}}>
        <LogoPackages/>
        </div>
        <MainFooter/>
    </div>
  )
}
