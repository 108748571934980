import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { ContactForm, InfoSection } from '../../../../component/contact'

export const ContactUs = () => {
  return (
    <div>
        <MainHeader/>
        <InfoSection/>
        <ContactForm/>
        <MainFooter/>
    </div>
  )
}
