import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { DescSectionSocial, InfoSectionSoical, SocialPackagesSection } from '../../../../component/social'

export const SocialMedia = () => {
  return (
    <div>
          <MainHeader/>
           <InfoSectionSoical/>
           <DescSectionSocial/>
           <SocialPackagesSection/>
          <MainFooter/>
    </div>
  )
}
