import React from 'react';
import preSeo from "../../../assest/images/seo/pre-seo.jpg"

export const DigitalVisibility = () => {
  return (
    <div>
           <section class="premium-txt">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <img src={preSeo} alt="preSeo" class="mar-img"/>
               </div>
               <div class="col-md-7">
                  <h6>ENHANCING</h6>
                  <h2>Digital Visibility</h2>
                  <p>At Go To Logo Experts, we are bring you premium Search Engine Optimization solutions that will help you put your brands in the limelight. Experienced optimizers, writers and link-builders are always at your disposal to make things happen exactly the way you want, or even better!</p>
                  <h5>ARCHITECTURE STUDY AND ANALYSIS OF WEBSITE</h5>
                  <p>A properly laid out website architecture is a strong pillar to implement SEO strategies, therefore, our SEO experts go through your website noting all minute details in order to ensure all pages aiming to grab the traffic can be easily found by search engines and rank your website higher and higher on your desired keywords.</p>
                  <h5>TARGET KEYWORD RESEARCH ANALYSIS</h5>
                  <p class="margin-zero">Precise keyword research is the foundation stone of a successful search engine optimization campaign and this is one the key skills of our experts. We make a thorough research and analysis of potential keywords that could be useful, profitable and bring targeted traffic to your website.</p>
                  {/* <div class="moreContnt" style="display: none;">
                     <p>We do not waste your time and money by bringing useless traffic but only targeted traffic that will fulfill your desired objectives. The carefully chosen keywords are used for on-page and off-page keyword optimization to rank your website and generate greater sales or fulfill any other call-to-action.</p>
                     <h5>PERFECT CONTENT STRATEGY & PLACEMENT</h5>
                     <p>We are not merely a backlink generating service but a result-driven professional SEO company. Our SEO and content writing experts help you manage your website content in the most effective fashion to make the visitor involve in the content and stay for longer time which also helps in reducing bounce rate, an important aspect of greater rankings.</p>
                     <h5>KEYWORD MONITORING AND PROVISION OF RANKING REPORTS</h5>
                     <p>We keep you updated of the ongoing SEO activities and send you comprehensive keyword ranking reports.</p>
                     <h5>TRAFFIC STATISTICS</h5>
                     <p>We also update you with number of visitors and the positive impact traffic brings to your business website.</p>
                     <h5>LOCAL SEO SERVICES</h5>
                     <p>If you need to good search engine ranking in a specific region, we serve you with the targeted SEO campaigns and help you achieve the best results in your desired region online and your website gets a high traffic volume out of the total number of visitors.</p>
                     <h5>GLOBAL SEO SERVICES</h5>
                     <p>If your keywords target global market, our SEO experts design the SEO strategies in a way that your website appear in high ranking in numerous countries and you receive a great deal of traffic to your website.</p>
                  </div> */}
                  <a href="javascript:;" class="btn btn-grey">Read More</a>
               </div>
            </div>
         </div>
    
      </section>
    </div>
  )
}
