import React, { useState } from "react";
import { webs } from "../../../data";
export const WebPortfolio = () => {
  const [displayedImages, setDisplayedImages] = useState(webs.slice(0, 6)); // Initially, show the first 6 images
  const [startIndex, setStartIndex] = useState(6);

  const getMoreImages = () => {
    const endIndex = startIndex + 6;
    setDisplayedImages([
      ...displayedImages,
      ...webs.slice(startIndex, endIndex),
    ]);

    setStartIndex(endIndex);
  };
  return (
    <div>
      <section className="mainpf  our-portfolio">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <h6>Our Recent</h6>
              <h2>Work</h2>
            </div>
            <div className="col-md-10">
              <p>
                We take an integrated approach to creating highly engaging
                digital properties & brand focused creative solutions. We
                believe in providing highly creative and exclusive brand
                identities giving your business an edge over its competitors.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <hr />

          <div className={`filter-item logo `}>
            {displayedImages.map((value) => {
              return (
                <div
                  class={`col-md-4 element-item`}
                  key={value.id}
                  category={`logo`}
                >
                  <img src={value.img} class="lazy" alt="" />
                </div>
              );
            })}
          </div>

          {displayedImages.length && (
            <button onClick={getMoreImages} id="showMore"></button>
          )}
        </div>
      </section>
    </div>
  );
};
