import React, {useState} from 'react'
import { valueData } from '../../../data';


export const LogoPortfolio = () => {
    const [displayedImages, setDisplayedImages] = useState(valueData.slice(0, 6)); // Initially, show the first 6 images
    const [startIndex, setStartIndex] = useState(6);

    const getMoreImages = () => {
        const endIndex = startIndex + 6;
        setDisplayedImages([
              ...displayedImages,
              ...valueData.slice(startIndex, endIndex),
            ]);
        
        setStartIndex(endIndex);
      };
  return (
    <div>
<section className="mainpf  our-portfolio">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <h6>Our Recent</h6>
              <h2>Work</h2>
            </div>
            <div className="col-md-10">
              <p>
                We, being one of the leading logo design companies offers you a
                chance to hire a logo designer who can understand your company's
                core message and ideology and world according to your business
                needs. We pour the utmost creativity and bring out the appeal
                with our incredible techniques. We delve deeper into the market
                to look for aspects that can sketch an authoritative image of
                the companies. The secret to increasing the overall conversion
                lies in the creative display of skills and that what we promise
                at our premier logo design company.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
         

          <hr />
          
          <div className={`filter-item logo `}>
       {displayedImages.map((value) => {
        return (
          <div
            class={`col-md-4 element-item`}
            key={value.id}
            category={`logo`}
          >
            <img src={value.img} class="lazy" alt="" />
          </div>
        );
      })}
    </div>
       
       {displayedImages.length && (
              <button onClick={getMoreImages} id="showMore"></button>
            )}
        </div>
      </section>
    </div>
  )
}
