import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { DigitalVisibility, IntroSectionSEO, SeoPackages } from '../../../../component/seo'


export const Seo = () => {
  return (
    <div>
        <MainHeader/>
        <IntroSectionSEO/>
        <DigitalVisibility/>
        <div style={{margin: "60px 0px"}}>
        <SeoPackages/>
        </div>
        <MainFooter/>
    </div>
  )
}
