import React from 'react';
import premAbout from "../../../assest/images/prem-about.png"

export const AboutContent = () => {
  return (
    <div>
        <section class="premium-txt prem-about">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <h6>About Us</h6>
                <h2>Goto Logo Experts</h2>
            </div>
            <div class="col-md-9">
                <p><strong>Goto Logo Experts</strong> is an energetic group of professional designers who are enthusiastic with their work and make the most excellent efforts to produce premium quality design solutions and make it a delightful experience for its clients.</p>
                <p><strong>Goto Logo Experts</strong> has been founded with the intention to provide high quality and affordable designing services to all the levels of business entities like startup businesses, small business organizations, companies and corporate and we have been quite successful in this industry as we employ the best designers.</p>
            </div>
            <div class="col-md-5">
                <img src={premAbout} alt="premAbout" class="mar-img"/>
            </div>
            <div class="col-md-7">
               <h3>Since designing is the crux of our all services,
                   we have made the teams of designers with
                   specific purposes such as:</h3>
                <ul>
                    <li>Business and company logo designers</li>
                    <li>Corporate identity designers</li>
                    <li>Graphics, banner, stationery and business card designers</li>
                    <li>Website designers</li>
                </ul>
                <p>The division of particular departments of designers helps us to focus on work with quality as every individual becomes the specialist and you as a client receive the best quality work from Goto Logo Experts. With years of research, learning and work experience, our every team member is an expert in his/her field and the departmentalization has further helped them to improve the standard of their skills. </p>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
