import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { Ctagroup } from '../../../../component/home'
import {Packages} from "../../../../component/packages";
import { AboutPacakges } from '../../../../component/about';

export const PricingPackages = () => {
  return (
    <div>
        <MainHeader/>
        {/* <Packages/> */}
        <AboutPacakges/>
        <Ctagroup/>
        <MainFooter/>
        
    </div>
  )
}
