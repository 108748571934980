import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { AnimatedVideo, IntroSectionVideo, VideoPortfolio } from '../../../../component/videos'
import { VideoPackagesComp } from '../../../../component/videos/videoPackages'

export const Videos = () => {
  return (
    <div>
        <MainHeader/>
          <IntroSectionVideo/>
          <AnimatedVideo/>
          <VideoPortfolio/>
          <div style={{margin: "30px 0px"}}>
          <VideoPackagesComp/>
          </div>
        <MainFooter/>
    </div>
  )
}
