import React from 'react'
import { OrderHeader } from '../../../orderHeader';
import "../order.css";
import { OrderForm } from '../../../../component/order';
import { OrderFooter } from '../../../orderFooter';

export const LogoBreif = () => {
  return (
    <div className='order-Section'>
        <OrderHeader/>
        <OrderForm/>
        <OrderFooter/>
    </div>
  )
}
