import React from 'react'
import { SliderSection, CustomDesign,OwnerShip,OurCategory, Ctagroup, Portfolio, Branding, Reviews,  Modal} from '../../../../component/home';
import { MainHeader } from '../../../header';
import "../mainindex.css";
import { MainFooter } from '../../../mainFooter';
import { Packages } from '../../../../component/packages';
export const HomeComp = () => {
  
  return (
    <div>
        <MainHeader/>
        <SliderSection/>
        <OwnerShip/>
        <CustomDesign/>
        <OurCategory/>
        <Ctagroup/>
        <Portfolio/>
        <Packages/>
        <Branding/>
        <Reviews/>
        <MainFooter/>
    </div>
  )
}
