import React from 'react';
import offerImg1 from "../../../assest/images/offer-ser-1.jpg";
import offerImg2 from "../../../assest/images/offer-ser-2.jpg";
import offerImg3 from "../../../assest/images/offer-ser-3.jpg";
import offerImg4 from "../../../assest/images/offer-ser-4.jpg";
import offerImg5 from "../../../assest/images/offer-ser-5.jpg";
import offerImg6 from "../../../assest/images/offer-ser-6.jpg";


export const AboutServices = () => {
  return (
    <div>
        <section class="offer-services">
    <div class="container">
        <div class="text-center">
            <h2>Services We Offer</h2>
            <p>Goto Logo Experts believes in delivering more than what's been expected from us.</p>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="box">
                    <img src={offerImg1} alt="offerImg1"/>
                    <div class="txt">
                        <h4>Logo Design</h4>
                        <p>Our designers design your logos and websites with utmost passion and enthusiasm.</p>
                        <a href="/" class="btn btn-grey">More <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box">
                    <img src={offerImg2} alt="offerImg2"/>
                    <div class="txt">
                        <h4>Website Design</h4>
                        <p>Bringing web to life - from simplest to the most complex models, we love them all!</p>
                        <a href="website-design.php" class="btn btn-grey">More <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box">
                    <img src={offerImg3} alt="offerImg3"/>
                    <div class="txt">
                        <h4>Video Animation</h4>
                        <p>We create unparalleled video animations to set new benchmarks of creativity.</p>
                        <a href="video-animation.php" class="btn btn-grey">More <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="box">
                    <img src={offerImg4} alt="offerImg4"/>
                    <div class="txt">
                        <h4>Stationery Design</h4>
                        <p>We brand your business to make your audience fall in love with it, like never before.</p>
                        <a href="stationery.php" class="btn btn-grey">More <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box">
                    <img src={offerImg5} alt="offerImg5"/>
                    <div class="txt">
                        <h4>Content Writing</h4>
                        <p>We provide quality content for your websites and ads that complement your creativity.</p>
                        <a href="content-writing.php" class="btn btn-grey">More <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box">
                    <img src={offerImg6} alt="offerImg6"/>
                    <div class="txt">
                        <h4>Seo Analytics</h4>
                        <p>Every business can achieve beyond the basics, when you show and know it right.</p>
                        <a href="seo.php" class="btn btn-grey">More <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
