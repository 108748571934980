import React, { useState } from "react";
import boreflogo1 from "../../../assest/Orderimg/breiflogo/sony-e-log.jpg";
import boreflogo2 from "../../../assest/Orderimg/breiflogo/anderoid-pic.jpg";
import boreflogo3 from "../../../assest/Orderimg/breiflogo/cola-pic.jpg";
import boreflogo4 from "../../../assest/Orderimg/breiflogo/egale-log.jpg";
import boreflogo5 from "../../../assest/Orderimg/breiflogo/fanta-log.jpg";
import boreflogo6 from "../../../assest/Orderimg/breiflogo/gn-lo.jpg";
import boreflogo7 from "../../../assest/Orderimg/breiflogo/kfc-log.jpg";
import boreflogo8 from "../../../assest/Orderimg/breiflogo/mico-pic.jpg";
import boreflogo9 from "../../../assest/Orderimg/breiflogo/porch-log.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";

import {
  logoPackages
} from "../../../data/portfolio";
export const OrderForm = () => {
  const { id } = useParams();

  let [getUrl, seGettUrl] = useState(id);

  let defaultDatas = [
    {
      id: 1,
      heading: "Logo Basic",
      desc: "Suitable for potential startups and brand revamps for companies.",
      price: "$59",
      list: [
        "4 Original Logo Concepts",
        "2 Dedicated Logo Designer",
        "4 Revisions",
        "With Grey Scale Format",
        "Free Icon Design",
        "Formats: JPEG Only",
        "24 - 48 Business Hours Turn Around Time",
        "100% Satisfaction",
        "100% Ownership Rights",
        "Money Back Guarantee",
        "Dedicated Account Manager",
      ],
    },
  ];
  let defaultImg = [
    {
      id: 1,
      img: boreflogo1,
      para: "high-tech",
    },
    {
      id: 2,
      img: boreflogo2,
      para: "minimalist",
    },
    {
      id: 3,
      img: boreflogo3,
      para: "typography",
    },
    {
      id: 4,
      img: boreflogo4,
      para: "corporate",
    },
    {
      id: 5,
      img: boreflogo5,
      para: "illustrative",
    },
    {
      id: 6,
      img: boreflogo6,
      para: "initials",
    },
    {
      id: 7,
      img: boreflogo7,
      para: "fun",
    },
    {
      id: 8,
      img: boreflogo8,
      para: "sporty",
    },
    {
      id: 9,
      img: boreflogo9,
      para: "shield",
    },
  ];


  function getfiletData(){
   return logoPackages.filter(value => value.id == id);
  }
  let finalData=getfiletData();



  const [formData, setFormData] = useState({
    logo_redesign: "",
    name_in_logo: "",
    tagline: "",
    description: "",
    thing_avoid: "",
    exist_website: "",
  });


  console.log(finalData);

  const setSubmitData =async (e) => {
    e.preventDefault();

    e.preventDefault();
    try {
      const apiUrl = `https://www.gotologoexperts.com/order/logo_brief.php?lead_id=${id}`;
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      let resultResponse={
        ...formData,
        logoType: finalData[0].heading,
        price: finalData[0].price,
        list: finalData[0].list,
        name: "sadasdasd",
        email: "khan@gmail.com",
        phone: "21653761253"
      }
    console.log(resultResponse);
      const formDataString = new URLSearchParams(resultResponse).toString();

      const response = await axios.post(apiUrl, formDataString, config);
      if(response.status == 200){
         console.log("Success",response);
      }
      console.log("responseresponseresponse",response);
    } catch (error) {
      console.error("Error:", error);
    }
      return false;


  };

  return (
    <div>
      <section class="page-title">
        <div class="container">
          <h1>Let's Get Started with Your Project</h1>
          <p>Please Provide the information requested below.</p>
        </div>
      </section>
      <section class="steps-seq">
        <div class="container">
          <ul id="progressbar">
            <li>
              <h4>Order Now</h4>
            </li>
            <li class="active">
              <h4>Logo Brief</h4>
            </li>
            <li>
              <h4>Payment</h4>
            </li>
          </ul>
        </div>
      </section>
      <section class="content-seq">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="boxpackages">
                {id == 0
                  ? defaultDatas.map((value) => {
                      return (
                        <>
                          <div class="packheads" key={value.id}>
                            <h3 class="montfont packageName">
                              {value.heading}
                            </h3>
                          </div>
                          <div class="packdetails">
                            <div class="packtitles">
                              <div class="fleft">
                                <h3 class="opensansfont packagePrice">
                                  {value.price}
                                </h3>
                              </div>
                            </div>
                            <div class="listpacks">
                              {value.list.map((value) => {
                                return <li>{value}</li>;
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })
                  : finalData.map((value) => {
                    return (
                      <>
                        <div class="packheads" key={value.id}>
                          <h3 class="montfont packageName">
                            {value.heading}
                          </h3>
                        </div>
                        <div class="packdetails">
                          <div class="packtitles">
                            <div class="fleft">
                              <h3 class="opensansfont packagePrice">
                                {value.price}
                              </h3>
                            </div>
                          </div>
                          <div class="listpacks">
                            {value.list.map((value) => {
                              return <li>{value}</li>;
                            })}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div class="col-md-8">
              <form class="logo_brief_form" onSubmit={setSubmitData}>
                {/* <input
                  type="hidden"
                  name="tkn"
                  data-name="tkn"
                  value=""
                  required
                /> */}
                <div class="col-md-12 form-sec step-1-form logo-brief-form">
                  <h4 style={{ marginBottom: "30px" }}>Logo Brief</h4>
                  <ul>
                    <li>
                      <label>
                        IS THIS A RE-DESIGN OR A NEW LOGO?{" "}
                        <span class="req-field-star">*</span>
                      </label>
                      <input
                        type="text"
                        name="logo_redesign"
                        data-name="logo_redesign"
                        placeholder=""
                        required
                        value={formData.logo_redesign}
                    onChange={(e) =>
                      setFormData({ ...formData, logo_redesign: e.target.value })
                    }
                      />
                    </li>
                    <li>
                      <label>
                        SELECT WHAT TYPE(S) OF YOUR LOGO SHOULD BE LOOK LIKE?
                      </label>
                      <div class="select-logo">
                        {/* <input
                          type="hidden"
                          name="logo_type"
                          data-name="logo_type"
                          id="logoSelect"
                          value=""
                          required
                        /> */}
                        <ul class="list-inline">
                          {defaultImg.map((img) => {
                            return (
                              <li key={img.id}>
                                <i>
                                  <img
                                    selected="0"
                                    class="bordr"
                                    src={img.img}
                                    width="151"
                                    height="151"
                                    alt="boreflogo1"
                                  />
                                </i>
                                <p>{img.para}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <label>
                        WORD/NAME THAT YOU WANT IN LOGO TO APPEAR (TYPE IN
                        EXACTLY AS YOU WANTED).
                        <span class="req-field-star">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        data-name="name_in_logo"
                        name="name_in_logo"
                        required
                        value={formData.name_in_logo}
                        onChange={(e) =>
                          setFormData({ ...formData, name_in_logo: e.target.value })
                        }
                        
                      />
                    </li>
                    <li>
                      <label>TAGLINE THAT YOU WANT IN LOGO TO APPEAR.</label>
                      <input
                        type="text"
                        name="tagline"
                        data-name="tagline"
                        placeholder=""
                        required
                        value={formData.tagline}
                        onChange={(e) =>
                          setFormData({ ...formData, tagline: e.target.value })
                        }
                      />
                    </li>
                    <li>
                      <label>
                        PLEASE PROVIDE A BRIEF DESCRIPTION OF YOUR BUSINESS. YOU
                        MAY ALSO WANT TO LIST WEBSITE ADDRESSES OF YOUR
                        COMPETITORS, IF ANY.
                      </label>
                      <input
                        type="text"
                        name="description"
                        data-name="description"
                        placeholder=""
                        required
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({ ...formData, description: e.target.value })
                        }
                      />
                    </li>
                    <li>
                      <label>
                        ANYTHING THAT YOU DO NOT LIKE AND WE SHOULD AVOID THAT
                        IN OUR DESIGN CONCEPT. (COLOR, PICTURE OR CERTAIN
                        NATURE, ETC)
                      </label>
                      <input
                        type="text"
                        name="thing_avoid"
                        data-name="thing_avoid"
                        placeholder=""
                        required
                        value={formData.thing_avoid}
                        onChange={(e) =>
                          setFormData({ ...formData, thing_avoid: e.target.value })
                        }
                      />
                    </li>
                    <li>
                      <label>EXISTING WEBSITE ADDRESS, IF ANY</label>
                      <input
                        type="text"
                        name="exist_website"
                        data-name="exist_website"
                        placeholder=""
                        required
                        value={formData.exist_website}
                        onChange={(e) =>
                          setFormData({ ...formData, exist_website: e.target.value })
                        }
                      />
                    </li>
                    <li class="submit-btn">
                      <div id="formResult"></div>
                      <button type="submit" class=" btn-red a-btn brief_submit">
                        Continue
                      </button>
                      {/* <div class="error mt-3 alert alert-danger text-left mb-0" style="display: none"></div> */}
                      {/* <div class="success mt-3 alert alert-success text-left mb-0" style="display: none"></div> */}
                      {/* <div class="loader" style="display: none"> */}
                      {/* <img alt="loader" src="loader.gif"/></div> */}
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
