import React from 'react'
import { MainHeader } from '../../../header';
import { MainFooter } from '../../../mainFooter';
import { Portfolio } from '../../../../component/home/portfolio';
import { Ctagroup } from '../../../../component/home';

export const WorkPortfolio = () => {
  return (
    <div>
        <MainHeader/>
        <Portfolio/>
        <Ctagroup/>
        <MainFooter/>
    </div>
  )
}
