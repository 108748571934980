import React from 'react';
import owner1 from "../../../assest/images/owner-1.png";
import owner2 from "../../../assest/images/owner-2.png";
import owner3 from "../../../assest/images/owner-3.png";
import owner4 from "../../../assest/images/owner-4.png";
import owner5 from "../../../assest/images/owner-5.png";

export const OwnerShip = () => {
  return (
    <div>
        <section className="ownership">
         <div className="container-fluid">
            <ul>
               <li><img src={owner1} alt="owner1"/><span>100% ownership <br/> rights</span></li>
               <li><img src={owner2} alt="owner2"/><span>Industry specific <br/> designers</span></li>
               <li><img src={owner3} alt="owner3"/><span>Dedicated <br/> designers</span></li>
               <li><img src={owner4} alt="owner4"/><span>24/7 customers <br/> support</span></li>
               <li><img src={owner5} alt="owner5"/><span>Highest satisfaction <br/> rate</span></li>
            </ul>
         </div>
      </section>
    </div>
  )
}
