import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import visa from "../../../assest/Orderimg/visa.png";
import verificationImg from "../../../assest/Orderimg/visa.png";
export const PaymentSection = () => {
  const { id } = useParams();

  return (
    <div>
      <section className="page-title">
        <div className="container">
          <h1>Let's Get Started with Your Project</h1>
          <p>Please Provide the information requested below.</p>
        </div>
      </section>
      <section className="steps-seq">
        <div className="container">
          <ul id="progressbar">
            <li>
              <h4>Order Now</h4>
            </li>
            <li>
              <h4>logo Brief</h4>
            </li>
            <li className="active">
              <h4>Payment</h4>
            </li>
          </ul>
        </div>
      </section>
      <section className="content-seq">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="boxpackages">
                <div className="packheads">
                  {/* <div className="productSku" style="display: none;"></div> */}
                  <h3 className="montfont packageName">Logo Elite</h3>
                </div>
                <div className="packdetails">
                  <div className="packtitles">
                    <div className="fleft">
                      <h3 className="opensansfont packagePrice">$499</h3>
                    </div>
                  </div>
                  <div className="listpacks">
                    {" "}
                    <li>List</li>{" "}
                  </div>
                  <div className="botarea-pack">
                    <div className="liovechats-bx">
                      <a href="javascript:;" title="" className="chat">
                        <span>Click here to</span>
                        <h5 className="montfont">Live Chat</h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <form action="receipt.php" method="POST" id="paymentFrm">
                <input
                  type="hidden"
                  placeholder=""
                  name="tkn"
                  readonly
                  id="tkn"
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="pkgName"
                  readonly
                  id="tkn"
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="amount"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="ip"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="city"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="state"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="country"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="brand_url"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="date_stamp"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="brand_name"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="source"
                  readonly
                  value=""
                />
                <input
                  type="hidden"
                  placeholder=""
                  name="briefDetails"
                  value=''
                />

                <div className="form-sec step-1-form logo-brief-form">
                  <h4 style={{ marginBottom: "30px" }}>Payment</h4>
                  <ul>
                    <li>
                      <label>DESCRIPTION </label>
                      <input
                        type="text"
                        placeholder=""
                        name="pkgName"
                        readonly
                        id="pname"
                        value=""
                      />
                    </li>
                    <li>
                      <label>Amount: USD </label>
                      <input
                        type="text"
                        placeholder=""
                        name="amount"
                        id="pprice"
                        readonly
                        value="sdasdsad"
                      />
                    </li>
                    <hr />
                    <li>
                      <label>
                        NAME <span className="req-field-star">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder=""
                        value=""
                      />
                    </li>

                    <li>
                      <label>
                        Telephone <span className="req-field-star">*</span>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        placeholder=""
                        value=""
                        maxlength="10"
                        onkeyup="javascript: this.value = this.value.replace(/[^0-9]/g,'');"
                      />
                    </li>
                    <hr />
                    <div id="example1-card">
                      <div className="example1"></div>
                    </div>
                    <img
                      className="img-responsive in-block"
                      src={visa}
                      style={{ textAlign: "center", margin: "0 auto" }}
                    />
                    <li>
                      <label>
                        CARD NAME: <span className="req-field-star">*</span>
                      </label>
                      <input
                        type="text"
                        name="card_name"
                        id="name"
                        value=""
                        className="field"
                        placeholder="Enter name"
                        required=""
                        autofocus=""
                      />
                    </li>
                    <li>
                      <label>
                        EMAIL <span className="req-field-star">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="field"
                        placeholder="Enter email"
                        required=""
                        value=""
                      />
                    </li>
                    <li>
                      <label>
                        CARD NUMBER: <span className="req-field-star">*</span>
                      </label>
                      <input
                        className="field"
                        type="text"
                        name="card_number"
                        placeholder="1234 1234 1234 1234"
                        autoComplete="off"
                        required=""
                      />
                    </li>
                    <li>
                      <label>
                        EXP DATE: <span className="req-field-star">*</span>
                      </label>
                      <div className="payment_fields">
                      <input
                        className="field"
                        type="text"
                        name="card_exp_month"
                        placeholder="MM"
                        required=""
                      />
                      <input
                        className="field"
                        type="text"
                        name="card_exp_year"
                        placeholder="YYYY"
                        required=""
                      />
                      </div>
                    </li>

                    <li>
                      <label>
                        CVV: <span className="req-field-star">*</span>
                      </label>
                      <input
                        className="field"
                        type="text"
                        name="card_cvv"
                        placeholder="CVC"
                        autoComplete="off"
                        required=""
                      />
                    </li>
                  </ul>
                </div>
                <div className="row paddingBottom descriptor">
                  <div className="col-lg-6 col-md-6 ">
                    <label className="field-txt">Statement Descriptor:</label>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <label className="field-txt">gotologoexperts.com</label>
                  </div>
                </div>
                <div className="privacy-terms paddingBottom">
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="tos"
                    checked=""
                    disabled=""
                  />
                  <label>
                    I accept{" "}
                    <a href="#" target="blank">
                      Terms condition
                    </a>{" "}
                    and{" "}
                    <a href="#" target="blank">
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
                <div className="col-md-12">
                  <div id="paymentResponse"></div>
                </div>
                <div className="form-sec">
                  <ul>
                    <li className="submit-btn">
                      <button
                        type="submit"
                        className="btn-red a-btn"
                        id="payBtn"
                      >
                        Submit
                      </button>
                      <img
                        className="img-responsive in-block"
                        src={verificationImg}
                        style={{ marginTop: "16px" }}
                      />
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
