import React from 'react'
import { MainHeader } from '../../../header'
import { MainFooter } from '../../../mainFooter'
import { DesignSolution, InfoSectionStationary, StationaryPackages, StationaryPortfolio } from '../../../../component/stationary'

export const Stationary = () => {
  return (
    <div>
         <MainHeader/>
         <InfoSectionStationary/>
         <DesignSolution/>
         <StationaryPortfolio/>
         <div style={{margin: "30px 0px"}}>
         <StationaryPackages/>
         </div>
         <MainFooter/>
    </div>
  )
}
