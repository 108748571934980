import React from 'react';
import video_animation from "../../../assest/images/video-animation/premium-video.jpg";
import video_animation1 from "../../../assest/images/video-animation/prem-video-icon1.png";
import video_animation2 from "../../../assest/images/video-animation/prem-video-icon2.png";
import video_animation3 from "../../../assest/images/video-animation/prem-video-icon3.png";
import video_animation4 from "../../../assest/images/video-animation/prem-video-icon4.png";

export const AnimatedVideo = () => {
  return (
    <div>
          <section class="premium-txt">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <img src={video_animation} alt="video_animation" class="mar-img"/>
               </div>
               <div class="col-md-7">
                  <h6>CUTTING-EDGE</h6>
                  <h2>Animated Videos</h2>
                  <p>At Go To Logo Experts, we are bring you premium video animation solutions that will help you put your brands in the limelight. Experienced designers and animators are always at your disposal to make things happen exactly the way you want, or even better!</p>
                  <p>If you are in search of a service that create 2D and 3D video animation with perfection, you have come to the right spot as we have the precise services you want.</p>
                  <p class="margin-zero">With a huge success of YouTube and other video hosting platforms, no one can deny the extreme interest of internet users in viewing videos on desktop browsers as well as mobile screens. Therefore, marketers are taking advantage of this medium and presence of video creation services has become inevitable.</p>
                
                  <a href="javascript:;" class="btn btn-grey">Read More</a>
               </div>
            </div>
         </div>
         <ul class="ser-bar">
            <li><img src={video_animation1} alt="video_animation1"/>2D Animation</li>
            <li><img src={video_animation2} alt="video_animation1"/>3D Animation</li>
            <li><img src={video_animation3} alt="video_animation1"/>Whiteboard Video</li>
            <li><img src={video_animation4} alt="video_animation1"/>Explainer Video</li>
         </ul>
     
      </section>
    </div>
  )
}
