import React from 'react'
import { MainHeader } from '../../../header'
import { ImageSection, AboutContent, AboutServices } from '../../../../component/about'
import { MainFooter } from '../../../mainFooter'

export const AboutUs = () => {
  return (
    <div>
        <MainHeader/>
        <ImageSection/>
        <AboutContent/>
        <AboutServices/>
        <MainFooter/>
    </div>
  )
}
