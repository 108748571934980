import React from 'react'

export const ImageSection = () => {
  return (
    <div>
        
<section class="inner-banner about-banner">
    <div class="container">
        <div class="text-center">
            <h6>Cutting-Edge</h6>
            <h1>Design Solutions, <br/> Unparalleled Skills</h1>
        </div>
        <h3>about us</h3>
    </div>
</section>
    </div>
  )
}
