import React from 'react';

export const OrderHeader = () => {
  return (
    <div className='header_section'>
        <header>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3">
            <a href="/" className="header-logo">
                <img src="https://www.gotologoexperts.com/assets/images/logo-black.png" alt=""/>
            </a>
          </div>
          <div className="col-md-9">
            <div className="phoneInfo">
              <ul>
                <li>
                  <a href="javascript:;" className="chat"><i className="chat-icon for-sprite"></i>
                  Start Live Chat</a>
                </li>
                <li>
                  <a href="(855) 535-2384"><i className="tell-icon for-sprite"></i> Call 24/7: <span>(855) 535-2384</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    </div>
  )
}
