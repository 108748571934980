import React from "react";
import { videoPackages } from "../../../data/portfolio";
import Slider from "react-slick";

export const VideoPackagesComp = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div>
        <section className="our-pricing multiple_pricing combo-pricing">
          <div className="container">
            <div className="text-center">
              <h2>VIDEO ANIMATION PACKAGES</h2>
              <p>
                Animation packages devised to best suit your needs. We promise
                you the best video animation services at highly competitive
                prices.
              </p>
            </div>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="logo"
                role="tabpanel"
                aria-labelledby="logo-tab"
              >
                <div className="row packages-slider owl-carousel owl-theme">
                  <div className={`filter-item branding `}>
                    <Slider {...settings}>
                      {videoPackages.map((value) => {
                        return (
                          <div className="item" key={value.id}>
                            <div className="pack">
                              <div className="head">
                                <h5 className="pckg-title">{value.heading}</h5>
                                <p>{value.desc}</p>
                                <div className="pckg-hd-title-box">
                                  <h5 className="title">{value.price}</h5>
                                </div>
                              </div>
                              <div className="mid-body">
                                <div className="content mCustomScrollbar">
                                  <ul>
                                    {value.list.map((list) => {
                                      return <li>{list}</li>;
                                    })}
                                  </ul>
                                </div>
                              </div>
                              <a
                                href="javascript:;"
                                data-fancybox
                                data-src="#popupform"
                                className="openpopup various btn btn-blue"
                              >
                                Start Project
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
