import React from "react";

export const OrderFooter = () => {
  return (
    <div className="order_footer">
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; Copyright 2023 Goto Logo Experts. All Rights Reserved
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-md-right">
                <a href="../privacy-policy.php" target="_blank">
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a href="../term-and-conditions.php" target="_blank">
                  Terms & Conditions
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
